import { createAsyncThunk } from '@reduxjs/toolkit';
import districtsApi from '../../../../store/api/district.api';
import { getLocalStorageDistrictId } from '../../../_core/utils';

export const initiateDistrict = createAsyncThunk('districts/initiateDistrict', async (_, { dispatch }) => {
	const currentDistrictId = getLocalStorageDistrictId();
	if (currentDistrictId) {
		await dispatch(districtsApi.endpoints.getDistrict.initiate({ id: currentDistrictId }, { forceRefetch: true }));
	}
});
