import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ShareIcon: FC<SvgIconProps> = (props) => (
	<SvgIcon {...props}>
		<svg fill="none" strokeWidth={1.5} stroke="currentColor">
			<g transform="translate(3, 3)">
				<path d="M14.6666 1.8335L7.33331 9.16683" />
				<path d="M14.6666 1.8335L9.99998 15.1668L7.33331 9.16683L1.33331 6.50016L14.6666 1.8335Z" />
			</g>
		</svg>
	</SvgIcon>
);
