import { FC } from 'react';
import { TableListQueryResponse } from '../../../_core/interfaces/table-list-query.interface';
import { FamilyDto, PermissionDto } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { CoreTable, CoreTableEmptyBody } from '../../../_core/components/_ui/core-table';
import { useFamiliesTableColumns } from '../../hooks/table-columns/families-table-columns.hook';

export interface FamiliesTableHandlers {
	onDelete(family: FamilyDto): void;
	onEdit(family: FamilyDto): void;
	onView(family: FamilyDto): void;
}

export type FamiliesTablePermission = {
	permission?: PermissionDto;
};

type FamiliesTableProps = TableListQueryResponse<FamilyDto> & FamiliesTableHandlers & FamiliesTablePermission;

export const FamiliesTable: FC<FamiliesTableProps> = (props) => {
	const {
		data,
		sorting,
		onSortingChange,
		loading,
		hasMore,
		fetchMore,
		scrollRef,
		onDelete,
		onEdit,
		onView,
		permission,
	} = props;

	const [t] = useTranslation();

	const columns = useFamiliesTableColumns(
		{
			onDelete,
			onEdit,
			onView,
		},
		permission
	);

	const table = useReactTable<FamilyDto>({
		data,
		columns,
		state: {
			sorting,
		},
		onSortingChange,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<CoreTable
			px={0}
			ref={scrollRef}
			loading={loading}
			hasMore={hasMore}
			threshold={200}
			emptyListComponent={
				<CoreTableEmptyBody label={t('profile.sessions.table.empty-title')} description={t('families.no-families')} />
			}
			fetchMore={fetchMore}
			rowModel={table.getRowModel()}
			headerGroups={table.getHeaderGroups()}
		/>
	);
};
