import { Box, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { EngagementLineChart } from '../components/line-chart-engagements/line-chart-engagements.component';
import { useTranslation } from 'react-i18next';

export const DashboardPage: FC = () => {
	const [t] = useTranslation();

	return (
		<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', mb: 1 }}>
			<Box sx={{ display: 'flex', flexDirection: 'column', width: '65%', height: '100%' }}>
				<Paper elevation={0} sx={{ height: '50%', width: '100%', display: 'flex', flexDirection: 'column', p: 2 }}>
					<Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
						{t('dashboard.eng-by-month')}
					</Typography>
					<Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<EngagementLineChart />
					</Box>
				</Paper>
				<Paper
					elevation={0}
					sx={{
						height: '50%',
						width: '100%',
						mt: 1,
					}}
				></Paper>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: '35%',
					height: '100%',
					justifyContent: 'space-between',
					ml: 1,
					mb: 1,
				}}
			>
				<Paper elevation={0} sx={{ flexGrow: 1, mb: 1 }}></Paper>
				<Paper
					elevation={0}
					sx={{
						flexGrow: 1,
						mb: 1,
					}}
				></Paper>
				<Paper
					elevation={0}
					sx={{
						flexGrow: 1,
					}}
				></Paper>
			</Box>
		</Box>
	);
};
