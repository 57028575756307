import { IsEmail, IsEnum, Length } from 'class-validator';
import { UserRolesEnum } from '../../enums';
import { UserDto } from './user.dto';

export class UpdateUserDataDto implements Pick<Partial<UserDto>, 'name'> {
	@Length(1, 100)
	name?: string;

	static instantiate(user: UserDto): UpdateUserDataDto {
		const updateUserDto = new UpdateUserDataDto();
		updateUserDto.name = user.name;

		return updateUserDto;
	}
}

export class UpdateUserDto {
	@IsEmail()
	email: string;

	@Length(1, 100)
	name: string;

	@IsEnum(UserRolesEnum)
	role: string;
}
