import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const InfoIcon: FC<SvgIconProps> = (props) => (
	<SvgIcon {...props}>
		<svg
			viewBox="0 0 40 40"
			stroke-width="3"
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
			fill="none"
		>
			<path d="M19.9998 3.33464C29.2045 3.33464 36.6665 10.7966 36.6665 20.0013C36.6665 29.206 29.2045 36.668 19.9998 36.668C10.7951 36.668 3.33313 29.206 3.33313 20.0013C3.33313 10.7966 10.7951 3.33464 19.9998 3.33464Z" />
			<path d="M20 13.3333V20" />
			<path d="M20 26.668H20.0167" />
		</svg>
	</SvgIcon>
);
