import { createAsyncThunk } from '@reduxjs/toolkit';
import familiesApi from '../../../../store/api/families.api';
import { getLocalStorageFamilyId } from '../../../_core/utils';

export const initiateFamily = createAsyncThunk('families/initiateFamily', async (_, { dispatch }) => {
	const currentFamilyId = getLocalStorageFamilyId();
	if (currentFamilyId) {
		await dispatch(familiesApi.endpoints.getFamily.initiate({ id: currentFamilyId }, { forceRefetch: true }));
	}
});
