import { FC } from 'react';
import { TableListQueryResponse } from '../../../_core/interfaces/table-list-query.interface';
import { SurveyTemplateDto, UserDto } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { CoreTable, CoreTableEmptyBody } from '../../../_core/components/_ui/core-table';
import { useSurveyTemplatesTableColumns } from '../../hooks/table-columns/survey-templates-table-columns.hook';

export interface SurveyTemplatesTableHandlers {
	onDelete(surveyTemplate: SurveyTemplateDto): void;
	onEdit(surveyTemplate: SurveyTemplateDto): void;
	onView(surveyTemplate: SurveyTemplateDto): void;
}

type SurveyTemplatesTableProps = TableListQueryResponse<SurveyTemplateDto> & SurveyTemplatesTableHandlers;

export const SurveyTemplatesTable: FC<SurveyTemplatesTableProps> = (props) => {
	const { data, sorting, onSortingChange, loading, hasMore, fetchMore, scrollRef, onEdit, onView, onDelete } = props;

	const [t] = useTranslation();

	const columns = useSurveyTemplatesTableColumns({
		onDelete,
		onEdit,
		onView,
	});

	const table = useReactTable<SurveyTemplateDto>({
		data,
		columns,
		state: {
			sorting,
		},
		onSortingChange,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<CoreTable
			px={0}
			ref={scrollRef}
			loading={loading}
			hasMore={hasMore}
			threshold={200}
			emptyListComponent={<CoreTableEmptyBody description={t('survey-templates.no-survey-templates')} />}
			fetchMore={fetchMore}
			rowModel={table.getRowModel()}
			headerGroups={table.getHeaderGroups()}
		/>
	);
};
