import {
	Length,
	IsEmail,
	IsEnum,
	IsNotEmpty,
	IsOptional,
	ValidationArguments,
	Matches,
	IsPhoneNumber,
} from 'class-validator';
import { LanguageEnum } from '../../enums';
import { Transform } from 'class-transformer';

export class ImportFamilyDto {
	@IsNotEmpty()
	districtId: string;
	@Length(1, 100)
	fullName: string;

	@IsEmail()
	email: string;

	@IsPhoneNumber('US', { message: 'Phone number must be a valid phone number' })
	phoneNumber: string;

	@Length(1)
	location?: string;

	@IsEnum(LanguageEnum, {
		message: (args: ValidationArguments) => {
			if (args.value === '' || args.value === null || args.value === undefined) {
				return 'Preferred language is required';
			} else {
				return 'Preferred language is invalid';
			}
		},
	})
	preferedLanguage: string;
	@IsOptional()
	imageUrl?: string;
}
