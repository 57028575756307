export enum CustomFieldDataTypeEnum {
	ShortText = 'Short text',
	LongText = 'Long text',
	Options = 'Options',
	MultiSelect = 'MultiSelect',
	File = 'Files',
}

export const CustomFieldDataTypeArray = [
	CustomFieldDataTypeEnum.ShortText,
	CustomFieldDataTypeEnum.LongText,
	CustomFieldDataTypeEnum.Options,
	CustomFieldDataTypeEnum.MultiSelect,
	CustomFieldDataTypeEnum.File,
];
