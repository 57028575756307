import { useTranslation } from 'react-i18next';
import { CoreModal, CoreModalProps } from '../../../_core/components/_ui/core-modal';
import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import { Box, Typography } from '@mui/material';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { DownloadIcon } from '../../../_core/constants/icons.constants';
import { useForm } from 'react-hook-form';
import { useGetProviderTemplateUrlQuery } from '../../../../store/api/providers.api';

export type ProviderImportModalProps = CoreModalProps & {
	submitAction(values: FormData): Promise<void>;
	closeModal(): void;
	formTitle?: string;
	defaultValues?: any;
	formError?: any;
	submitButtonName?: string;
};

export const ProviderImportModal: React.FC<ProviderImportModalProps> = ({
	submitAction,
	closeModal,
	...modalProps
}) => {
	const [t] = useTranslation();

	const {
		setValue,
		trigger,
		formState: { errors },
	} = useForm();
	const [file, setFile] = useState<File | null>(null);

	const { data: templateData } = useGetProviderTemplateUrlQuery();

	const { enqueueSnackbar } = useSnackbar();

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			const selectedFile = acceptedFiles[0];
			setFile(selectedFile);
			setValue('csvFile', selectedFile);
			trigger('csvFile');
		},
		[setValue, trigger]
	);

	const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
		onDrop,
		multiple: false,
		accept: {
			'text/*': ['.csv'],
		},
	});

	const handleFileImport = async () => {
		if (!file) return;

		if (!file.name.endsWith('.csv') || file.type !== 'text/csv') {
			enqueueSnackbar(t('providers.invalid-file-type'), {
				variant: 'error',
				autoHideDuration: 3000,
			});
			return;
		}

		const formData = new FormData();
		formData.append('file', file);
		try {
			await submitAction(formData);
		} catch (error) {
			enqueueSnackbar('Failed to import the file.', { variant: 'error' });
		}
	};

	return (
		<CoreModal {...modalProps} bodySx={{ pt: 0 }} paperSx={{ width: '100%' }}>
			<Box component="form">
				<Box
					{...getRootProps()}
					sx={{
						border: '2px dashed #cccccc',
						borderRadius: '10px',
						textAlign: 'center',
						padding: '20px',
						bgcolor: isDragActive ? '#e0f7fa' : '#ffffff',
						transition: 'background-color 0.3s',
						mb: 2,
					}}
				>
					<input {...getInputProps()} />
					{file ? (
						<Typography variant="body1">{file.name}</Typography>
					) : (
						<Typography variant="body2">{t('providers.select-file')}</Typography>
					)}
					<CoreButton variant="secondary" onClick={open} sx={{ my: 2 }}>
						{t('providers.choose-file')}
					</CoreButton>
				</Box>

				<a href={templateData?.url} style={{ textDecoration: 'none' }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							width: '100%',
							mb: 2,
						}}
					>
						<Typography>{t('providers.import-template')}</Typography>
						<Box sx={{ ml: 1 }}>
							<DownloadIcon />
						</Box>
					</Box>
				</a>
				<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
					<CoreButton onClick={closeModal} variant="secondary" contraMode sx={{ marginRight: 1 }}>
						{t('families.forms.buttons.cancel')}
					</CoreButton>
					<CoreButton type="submit" contraMode onClick={handleFileImport}>
						{t('families.forms.buttons.import')}
					</CoreButton>
				</Box>
			</Box>
		</CoreModal>
	);
};
