export enum CustomDataTypeEnum {
	ShortText = 'Short text',
	LongText = 'Long text',
	Options = 'Options',
	File = 'Files',
}

export const CustomDataTypeArray = [
	CustomDataTypeEnum.ShortText,
	CustomDataTypeEnum.LongText,
	CustomDataTypeEnum.Options,
	CustomDataTypeEnum.File,
];
