import React, { FC, useCallback } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { CoreModal, CoreModalProps } from '../../../../_core/components/_ui/core-modal';
import { CoreButton } from '../../../../_core/components/_ui/core-button';
import { CreateDistrictDto, SurveyTemplateDto } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import {
	containerButtonStyles,
	leftButtonStyles,
	inputBoxStyles,
	modalBoxStyles,
	errorTextStyles,
	selectListTypographyOverflowStyles,
} from './create-form.styles';
import { buttonModalWindowStyles, mainTitleModalWindowStyles } from '../../../../_core/styles';
import { mergeSx } from 'merge-sx';
import { CoreAutoComplete } from '../../../../_core/components/_ui/core-autocomplete';

export type CreateSurveyFormModalProps = CoreModalProps & {
	surveyTemplatesList?: SurveyTemplateDto[];
	formError?: any;
	submitAction(values: CreateDistrictDto): Promise<void>;
	closeModal(): void;
};

export const CreateSurveyFormModal: FC<CreateSurveyFormModalProps> = (props) => {
	const { surveyTemplatesList, formError, submitAction, closeModal, ...modalProps } = props;
	const [t] = useTranslation();

	const defaultValues: any = { surveyTemplate: '' };

	const {
		register,
		control,
		handleSubmit,
		formState: { errors, isDirty, isLoading, isSubmitting, isSubmitted, isValid },
	} = useForm({
		defaultValues,
		mode: 'all',
	});

	const disableSubmit = !isValid && (isDirty || isSubmitted);
	const loadingSubmit = isLoading || isSubmitting;

	const handleFormSubmit = useCallback(
		async (values: CreateDistrictDto) => {
			await submitAction(values);
		},
		[submitAction]
	);

	return (
		<CoreModal {...modalProps} bodySx={{ pt: 0 }} paperSx={{ width: '100%', maxWidth: '550px' }}>
			<Box sx={modalBoxStyles}>
				<Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
					<Box sx={inputBoxStyles}>
						<CoreAutoComplete
							label={t('surveys.forms.labels.template')}
							fullWidth
							requiredMark
							ruleIsRequired
							ruleName="Template"
							{...register('surveyTemplate')}
							control={control}
							error={!!errors['surveyTemplate']}
							helperText={errors['surveyTemplate'] ? (errors['surveyTemplate'] as FieldError).message : undefined}
							options={surveyTemplatesList || []}
							getOptionLabel={(option) => option.title || ''}
							selectOnFocus={true}
							isOptionEqualToValue={(option, value) => option._id === value._id}
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder={t('surveys.forms.placeholders.template')}
									sx={{
										'& .MuiOutlinedInput-root': {
											'&.Mui-focused fieldset': {
												borderColor: (theme) => theme.colors.blue,
											},
										},
									}}
								/>
							)}
							renderOption={(props, option) => (
								<MenuItem {...props} key={option._id} sx={{ display: 'flex', alignItems: 'center' }}>
									<Typography sx={selectListTypographyOverflowStyles}>{option.title}</Typography>
								</MenuItem>
							)}
							controlSx={inputBoxStyles}
						/>
					</Box>
					{formError && (
						<Typography sx={errorTextStyles}>
							{formError?.data?.message ? formError.data.message : t('errors.invalid-credentials-error')}
						</Typography>
					)}
					<Box sx={containerButtonStyles}>
						<CoreButton
							onClick={closeModal}
							variant="secondary"
							contraMode
							sx={mergeSx(buttonModalWindowStyles, leftButtonStyles)}
						>
							{t('districts.forms.buttons.close-form')}
						</CoreButton>
						<CoreButton
							type="submit"
							contraMode
							disabled={disableSubmit}
							loading={loadingSubmit}
							sx={buttonModalWindowStyles}
						>
							{t('surveys.create-button')}
						</CoreButton>
					</Box>
				</Box>
			</Box>
		</CoreModal>
	);
};
