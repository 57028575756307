import React, { FC, useEffect } from 'react';
import { SurveyTemplatesTable } from '../../components/survey-templates-table';
import { Box, Container } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { useTranslation } from 'react-i18next';
import { SurveyTemplateDto } from '@families-link/shared';
import { useSortingParams, usePaginatedData, useInitiateState } from '../../../_core/hooks';
import { useDeleteSurveyTemplateMutation, useGetSurveyTemplatesListQuery } from '../../../../store/api/survey.api';
import { useOpenConfirmModal } from '../../hooks/modal-forms/use-open-confirm-modal-form.hook';
import { getLocalStorageDistrictId, setLocalStorageSurveyTemplateId } from '../../../_core/utils';
import AddIcon from '@mui/icons-material/Add';
import { DistrictRoutes } from '../../../district/constants';
import { useNavigate, generatePath } from 'react-router-dom';

export const SurveyTemplatesListPage: FC = () => {
	useInitiateState('district');

	const [t] = useTranslation();
	const navigate = useNavigate();
	const currentDistrict = getLocalStorageDistrictId() || '';

	const { sortingArray, sortingParams, handleSortingChange } = useSortingParams();
	const take = 15;
	const { isFetching, meta, page, handleResetPage, itemsList, refetch, fetchMore } =
		usePaginatedData<SurveyTemplateDto>(useGetSurveyTemplatesListQuery, {
			take,
			...sortingParams,
			district: currentDistrict,
		});

	useEffect(() => {
		handleResetPage();
	}, [sortingArray]);

	const handlePagination = () => {
		handleResetPage();
		if (page === 1) {
			refetch();
		}
	};

	const handleOpenCreate = () => {
		navigate(DistrictRoutes.SurveyTemplates.Creating.replace(':districtId', currentDistrict));
	};

	const handleOpenEdit = (surveyTemplate: SurveyTemplateDto) => {
		setLocalStorageSurveyTemplateId(surveyTemplate._id);
		navigate(
			DistrictRoutes.SurveyTemplates.Editing.replace(':districtId', currentDistrict).replace(
				':surveyTemplateId',
				surveyTemplate._id
			),
			{ state: { data: surveyTemplate } }
		);
	};

	const [deleteSurveyTemplate] = useDeleteSurveyTemplateMutation();
	const featchActionDelete = async (data: SurveyTemplateDto) => {
		await deleteSurveyTemplate({ id: data._id }).unwrap();
	};
	const handleOpenDeleteConfirmModal = useOpenConfirmModal({
		featchAction: featchActionDelete,
		handlePagination,
		title: t('survey-templates.forms.delete-title'),
		description: t('survey-templates.forms.delete-description'),
		icon: <DeleteOutlineOutlinedIcon />,
		danger: true,
		submitButtonName: t('survey-templates.forms.buttons.delete'),
		toastText: 'Survey template was successfully deleted',
	});

	const handleOpenView = (surveyTemplate: SurveyTemplateDto) => {
		setLocalStorageSurveyTemplateId(surveyTemplate._id);

		const url = generatePath(DistrictRoutes.SurveyTemplates.Details, { surveyTemplateId: surveyTemplate._id });
		window.open(url, '_blank');
	};

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
				<CoreButton
					sx={{
						display: 'flex',
						fontSize: '16px',
						padding: '12px 24px',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '8px',
						marginLeft: 'auto',
					}}
					onClick={() => {
						handleOpenCreate();
					}}
				>
					<AddIcon />
					{t('survey-templates.create-survey')}
				</CoreButton>
			</Box>
			<SurveyTemplatesTable
				data={itemsList}
				loading={isFetching}
				hasMore={meta?.hasNextPage}
				onDelete={handleOpenDeleteConfirmModal}
				onEdit={handleOpenEdit}
				onView={handleOpenView}
				sorting={sortingArray}
				onSortingChange={handleSortingChange}
				fetchMore={fetchMore}
			/>
		</Container>
	);
};
