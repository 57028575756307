import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import dayjs from 'dayjs';

type DurationFilter = {
	start: any;
	end: any;
};

export const useDurationFilter = () => {
	const [transformDuration, setTransformDuration] = useState<DurationFilter>();
	const name = 'duration';
	const { control, watch } = useForm();
	const currentDuration: any = watch(name);

	const debouncedUpdate = useCallback(
		debounce((currentDuration: any) => {
			if (currentDuration && currentDuration?.length > 1) {
				setTransformDuration({
					start: dayjs(currentDuration[0]).format('YYYY-MM-DD'),
					end: currentDuration[1] ? dayjs(currentDuration[1]).format('YYYY-MM-DD') : '',
				});
			} else {
				setTransformDuration({
					start: undefined,
					end: undefined,
				});
			}
		}, 300),
		[]
	);

	useEffect(() => {
		debouncedUpdate(currentDuration);
	}, [currentDuration, debouncedUpdate]);

	return {
		name,
		control,
		currentDuration,
		transformDuration,
	};
};
