import { SxProps, Theme } from '@mui/material';

export const mainTitleModalWindowStyles: SxProps<Theme> = [
	{
		fontSize: '24px',
		fontWeight: 'bold',
	},
];

export const buttonModalWindowStyles: SxProps<Theme> = [
	{
		fontSize: '14px',
	},
	({ breakpoints }) => ({
		[breakpoints.down('sm')]: {
			p: 0.5,
		},
	}),
];

export const selectListTypographyOverflowStyles: SxProps<Theme> = [
	{
		width: '100%',
		overflowX: 'hidden',
		textOverflow: 'ellipsis',
	},
];
