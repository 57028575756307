import { FC } from 'react';
import { TableListQueryResponse } from '../../../../_core/interfaces/table-list-query.interface';
import { FamilyWithSurveyDto } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { CoreTable, CoreTableEmptyBody } from '../../../../_core/components/_ui/core-table';
import { useFamiliesDetailsTableColumns } from '../../../hooks/table-columns/families-details-table-colums.hook';

export interface FamiliesDetailsTableHandlers {
	onSendEmail(familyWithSurvey: FamilyWithSurveyDto): void;
	onSendSms(familyWithSurvey: FamilyWithSurveyDto): void;
	onEdit(familyWithSurvey: FamilyWithSurveyDto): void;
	onView(familyWithSurvey: FamilyWithSurveyDto): void;
}

type FamiliesDetailsTableProps = TableListQueryResponse<FamilyWithSurveyDto> & FamiliesDetailsTableHandlers;

export const FamiliesDetailsTable: FC<FamiliesDetailsTableProps> = (props) => {
	const {
		data,
		sorting,
		onSortingChange,
		loading,
		hasMore,
		fetchMore,
		scrollRef,
		onSendEmail,
		onSendSms,
		onEdit,
		onView,
	} = props;

	const [t] = useTranslation();

	const columns = useFamiliesDetailsTableColumns({
		onSendEmail,
		onSendSms,
		onEdit,
		onView,
	});

	const table = useReactTable<FamilyWithSurveyDto>({
		data,
		columns,
		state: {
			sorting,
		},
		onSortingChange,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<CoreTable
			px={0}
			ref={scrollRef}
			loading={loading}
			hasMore={hasMore}
			threshold={200}
			emptyListComponent={
				<CoreTableEmptyBody label={t('profile.sessions.table.empty-title')} description={t('families.no-families')} />
			}
			fetchMore={fetchMore}
			rowModel={table.getRowModel()}
			headerGroups={table.getHeaderGroups()}
		/>
	);
};
