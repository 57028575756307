import React, { FC, forwardRef, ReactNode, RefAttributes, useState } from 'react';
import { CoreFormControl, CoreFormControlProps } from '../core-form-control';
import { Autocomplete, AutocompleteProps } from '@mui/material';
import { DropdownArrow } from './dropdown-arrow.component';
import { Controller, ControllerProps } from 'react-hook-form';

export interface CoreAutocompleteProps
	extends CoreFormControlProps,
		Omit<ControllerProps, 'render'>,
		AutocompleteProps<any, boolean, boolean, boolean> {
	options: any[];
	ruleIsRequired?: boolean;
	ruleName?: string;
	multiple?: boolean;
	disableClearable?: boolean;
	freeSolo?: boolean;
	IconComponent?: ReactNode;
}

const validateRequired = (value: any, name?: string) => {
	if (!value) {
		return `${name || 'This field'} is required`;
	}
	return true;
};

export const CoreAutoComplete: FC<Omit<CoreAutocompleteProps, 'ref'> & RefAttributes<unknown>> = forwardRef(
	(props, ref) => {
		const {
			options,
			ruleIsRequired,
			ruleName,
			multiple = false,
			disableClearable = false,
			freeSolo = false,
			focused,
			helperText,
			error,
			controlSx,
			disabled,
			fullWidth,
			label,
			labelHint,
			requiredMark,
			id,
			IconComponent,
			name,
			control,
			...rest
		} = props;

		const [manualFocused, setManualFocused] = useState(false);

		return (
			<CoreFormControl
				label={label}
				labelHint={labelHint}
				id={id}
				requiredMark={requiredMark}
				fullWidth={fullWidth}
				focused={manualFocused}
				controlSx={controlSx}
				helperText={helperText}
				error={error}
				disabled={disabled}
			>
				<Controller
					name={name}
					control={control}
					rules={
						ruleIsRequired ? { validate: (value) => validateRequired(value, ruleName) } : { validate: (value) => true }
					}
					render={({ field }) => (
						<Autocomplete
							{...rest}
							{...field}
							ref={ref}
							multiple={multiple}
							disabled={disabled}
							disableClearable={disableClearable}
							freeSolo={freeSolo}
							options={options}
							onFocus={() => setManualFocused(true)}
							onBlur={() => setManualFocused(false)}
							onChange={(_, value) => field.onChange(value)}
							popupIcon={IconComponent || <DropdownArrow />}
							className="autocomplete"
							sx={{
								'& .MuiAutocomplete-tag': {
									height: '20px',
								},
								'& .MuiOutlinedInput-root ': {
									padding: '13px 16px',
								},
								'& .MuiOutlinedInput-root  .MuiOutlinedInput-input': {
									height: '28px',
									display: 'flex',
									alignItems: 'center',
								},

								'&.MuiAutocomplete-clearIndicator': {
									padding: '2px',
								},
								'& fieldset': {
									height: '100%',
									top: '-2px',
									borderColor: (theme) => theme.colors.blueTransparent,
								},

								'&.MuiAutocomplete-root .MuiOutlinedInput-root  .MuiAutocomplete-input': {
									padding: 0,
								},
								'& .MuiInputBase-root-MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline': {
									'& fieldset': {
										borderColor: (theme) => theme.colors.red,
									},
								},
							}}
						/>
					)}
				/>
			</CoreFormControl>
		);
	}
);
