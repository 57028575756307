import { FC } from 'react';
import { TableListQueryResponse } from '../../../_core/interfaces/table-list-query.interface';
import { UserDto } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { CoreTable, CoreTableEmptyBody } from '../../../_core/components/_ui/core-table';
import { useUsersTableColumns } from '../../hooks/table-columns/users-table-columns.hook';

export interface UsersTableHandlers {
	onDelete(user: UserDto): void;
	onEdit(user: UserDto): void;
	onMailSend(user: UserDto): void;
	onResetPassword(user: UserDto): void;
}

type UsersTableProps = TableListQueryResponse<UserDto> & UsersTableHandlers;

export const UsersTable: FC<UsersTableProps> = (props) => {
	const {
		data,
		sorting,
		onSortingChange,
		loading,
		hasMore,
		fetchMore,
		scrollRef,
		onEdit,
		onMailSend,
		onResetPassword,
		onDelete,
	} = props;

	const [t] = useTranslation();

	const columns = useUsersTableColumns({
		onDelete,
		onEdit,
		onMailSend,
		onResetPassword,
	});

	const table = useReactTable<UserDto>({
		data,
		columns,
		state: {
			sorting,
		},
		onSortingChange,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<CoreTable
			px={0}
			ref={scrollRef}
			loading={loading}
			hasMore={hasMore}
			threshold={200}
			emptyListComponent={
				<CoreTableEmptyBody label={t('profile.sessions.table.empty-title')} description={t('users.no-users')} />
			}
			fetchMore={fetchMore}
			rowModel={table.getRowModel()}
			headerGroups={table.getHeaderGroups()}
		/>
	);
};
