import { SxProps, Theme } from '@mui/material';

export const containerStyles: SxProps<Theme> = {
	height: 48,
	display: 'flex',
	alignItems: 'center',
	borderBottom: '1px solid',
	borderColor: (theme) => theme.colors.divider,
	'&:last-child': {
		border: 'none',
	},
};

export const wrapperStyle: SxProps<Theme> = {
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	overflow: 'hidden',
	mr: '10px',
};
