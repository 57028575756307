import { SxProps, Theme } from '@mui/material';

export const modalBoxStyles: SxProps<Theme> = [
	{
		m: 2,
	},
	({ breakpoints }) => ({
		[breakpoints.down('sm')]: {
			m: 1,
		},
	}),
];

export const containerFormStyles: SxProps<Theme> = [
	{
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		'& >div:first-child': {
			marginRight: 1,
		},
	},
	({ breakpoints }) => ({
		[breakpoints.down('sm')]: {
			flexDirection: 'column',
			justifyContent: 'flex-start',
		},
	}),
];

export const autocompleteBoxStyles: SxProps<Theme> = [
	{
		my: 1,
		'.autocomplete': {
			'&.MuiFormHelperText-root': {
				position: 'absolute',
				margin: 0,
				bottom: '-4px',
				fontSize: '12px',
				left: 0,
			},
			'&.MuiFormLabel-root ': {
				marginBottom: '2px',
			},
			'&.MuiOutlinedInput-root .MuiSelect-icon': {
				top: '19px',
			},
		},
	},
	({ breakpoints }) => ({
		[breakpoints.down('sm')]: {
			my: 0.5,
		},
	}),
];

export const inputBoxStyles: SxProps<Theme> = [
	{
		my: 1,
		'& .MuiFormHelperText-root': {
			position: 'absolute',
			margin: 0,
			bottom: '-4px',
			fontSize: '12px',
			left: 0,
		},
		'& .MuiFormLabel-root ': {
			marginBottom: '2px',
		},
		'& .MuiInputBase-input .MuiOutlinedInput-root': {
			marginBottom: '12px',
		},
		'& .MuiOutlinedInput-root': {
			marginBottom: '12px',
		},
		'& .MuiOutlinedInput-root .MuiSelect-icon': {
			top: '19px',
		},
		'& .MuiOutlinedInput-root  .MuiOutlinedInput-input': {
			height: '28px',
			display: 'flex',
			alignItems: 'center',
		},
		'& fieldset': {
			height: '100%',
			top: '-2px',
		},
	},
	({ breakpoints }) => ({
		[breakpoints.down('sm')]: {
			my: 0.5,
		},
	}),
];

export const containerButtonStyles: SxProps<Theme> = [
	{
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
];

export const errorTextStyles: SxProps<Theme> = [
	{
		fontWeight: 'bold',
		color: (theme) => theme.colors.red,
		pb: 2,
	},
];

export const buttonStyles: SxProps<Theme> = [
	({ breakpoints }) => ({
		[breakpoints.down('sm')]: {
			p: 0.5,
		},
	}),
];

export const leftButtonStyles: SxProps<Theme> = [
	{
		mr: 2,
	},
	({ breakpoints }) => ({
		[breakpoints.down('sm')]: {
			mr: 1,
			p: 0.5,
		},
	}),
];
