import { Box, Container, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ProvidersDetails } from '../../../providers/components/providers-details/providers-details.component';
import { useGetEngagementQuery } from '../../../../store/api/engagements.api';
import { getLocalStorageDistrictId, getLocalStorageEngagementId } from '../../../_core/utils';
import { EngagementDetails } from '../../components/engagement-details/engagement-details.component';
import { useInitiateState, useSortingParams } from '../../../_core/hooks';
import { FamilyDetailsList } from '../../../family/components/family-details/family-details-list.component';
import {
	useGetAllSurveyTemplatesListQuery,
	useCreateEngagementSurveyMutation,
	useCheckExistEngagementSurveyQuery,
	useSendSurveyToFamiliesMutation,
	useGetListEngagementFamiliesWithSurveyQuery,
	useSendSurveyByEmailMutation,
	useLazyGetSurveyLinkQuery,
	useLazyGetSurveyAnswersQuery,
} from '../../../../store/api/survey.api';

export const EngagementDetailsPage: FC = () => {
	useInitiateState('engagement');

	const [t] = useTranslation();

	const engagementId = getLocalStorageEngagementId();
	const currentDistrict = getLocalStorageDistrictId();
	const { data: currentEngagement } = useGetEngagementQuery({ id: engagementId });
	const { data: existSurvey } = useCheckExistEngagementSurveyQuery(engagementId ?? '');
	const { data: surveyTemplatesList } = useGetAllSurveyTemplatesListQuery(currentDistrict ?? '');
	const { sortingArray, handleSortingChange, sortingParams } = useSortingParams();
	const { data: familiesWithSurvey, isFetching } = useGetListEngagementFamiliesWithSurveyQuery({
		id: engagementId ?? '',
		...sortingParams,
	});
	const [triggerLink] = useLazyGetSurveyLinkQuery();
	const [triggerAnswer] = useLazyGetSurveyAnswersQuery();
	const [createEngagementSurvey] = useCreateEngagementSurveyMutation();
	const [sendSurveyToFamilies] = useSendSurveyToFamiliesMutation();
	const [sendSurveyByEmail] = useSendSurveyByEmailMutation();

	return (
		<Container
			maxWidth={false}
			disableGutters
			sx={{
				display: 'flex',
				flexDirection: 'row',
				width: '100%',
				padding: 0,
				margin: 0,
				height: '100vh',
				overflow: 'auto',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: '50%',
					height: '100%',
					mr: 2,
				}}
			>
				<Box sx={{ height: '32%', mb: 2 }}>
					<Paper elevation={0} sx={{ width: '100%', height: '100%' }}>
						{currentEngagement?.providerId ? (
							<ProvidersDetails providerId={currentEngagement.providerId} />
						) : (
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'flex-start',
									width: '100%',
									height: '100%',
									mt: 1,
									ml: 3,
								}}
							>
								<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
									{t('providers.details')}
								</Typography>
							</Box>
						)}
					</Paper>
				</Box>
				<Paper
					elevation={0}
					sx={{
						width: '100%',
						flexGrow: 1,
						pt: 2,
						pb: 2,
						pr: 3,
						pl: 3,
						borderBottomRightRadius: 0,
						borderBottomLeftRadius: 0,
						overflowY: 'auto',
					}}
				>
					<EngagementDetails />
				</Paper>
			</Box>
			<Box sx={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'column' }}>
				<Paper elevation={0} sx={{ width: '100%', height: '100%' }}>
					<FamilyDetailsList
						engagementId={currentEngagement?._id.toString() ?? ''}
						itemsList={familiesWithSurvey}
						isFetching={isFetching}
						engagementSurvey={existSurvey}
						createSurveyAction={createEngagementSurvey}
						surveyTemplatesList={surveyTemplatesList}
						sendSurveyAction={sendSurveyToFamilies}
						sendSurveyByEmailAction={sendSurveyByEmail}
						// surveyLink={surveyLink}
						triggerSurveyLink={triggerLink}
						// surveyAnswer={surveyAnswer}
						triggerSurveyAnswer={triggerAnswer}
						sortingArray={sortingArray}
						handleSortingChange={handleSortingChange}
					/>
				</Paper>
			</Box>
		</Container>
	);
};
