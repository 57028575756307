import React, { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { CoreModal, CoreModalProps } from '../../../_core/components/_ui/core-modal';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { DistrictDto, UpdateDistrictDto } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import {
	buttonStyles,
	containerButtonStyles,
	inputBoxStyles,
	leftButtonStyles,
	modalBoxStyles,
	errorTextStyles,
} from './edit-form.styles';
import { buttonModalWindowStyles, mainTitleModalWindowStyles } from '../../../_core/styles';
import { mergeSx } from 'merge-sx';

export type EditDistrictFormModalProps = CoreModalProps & {
	defaultValues?: DistrictDto;
	formError?: any;
	submitAction(values: UpdateDistrictDto): Promise<void>;
	closeEditModal(): void;
};

export const EditDistrictFormModal: FC<EditDistrictFormModalProps> = (props) => {
	const [t] = useTranslation();
	const { defaultValues, submitAction, formError, closeEditModal, ...modalProps } = props;
	const resolver = classValidatorResolver(UpdateDistrictDto);
	const {
		register,
		handleSubmit,
		formState: { errors, touchedFields, isDirty, isLoading, isSubmitting, isSubmitted, isValid },
	} = useForm({
		defaultValues,
		resolver,
		mode: 'all',
	});

	const disableSubmit = !isValid && (isDirty || isSubmitted);
	const loadingSubmit = isLoading || isSubmitting;

	const handleFormSubmit = useCallback(
		async (values: UpdateDistrictDto) => {
			await submitAction(values);
		},
		[submitAction]
	);

	return (
		<CoreModal {...modalProps} bodySx={{ pt: 0 }} paperSx={{ width: '100%', maxWidth: '550px' }}>
			<Box sx={modalBoxStyles}>
				<Typography variant="h5" sx={mainTitleModalWindowStyles}>
					{t('districts.forms.edit-districts')}
				</Typography>
				<Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
					<Box sx={inputBoxStyles}>
						<CoreTextField
							label={t('districts.forms.labels.name')}
							requiredMark
							fullWidth
							{...register('name')}
							error={!!errors['name']}
							helperText={errors['name'] && errors['name']?.message}
						/>
					</Box>
					{formError && (
						<Typography sx={errorTextStyles}>
							{formError?.data?.message ? formError.data.message : t('errors.invalid-credentials-error')}
						</Typography>
					)}
					<Box sx={containerButtonStyles}>
						<CoreButton
							onClick={closeEditModal}
							variant="secondary"
							sx={mergeSx(buttonModalWindowStyles, leftButtonStyles)}
						>
							{t('districts.forms.buttons.close-form')}
						</CoreButton>
						<CoreButton
							type="submit"
							contraMode
							disabled={disableSubmit}
							loading={loadingSubmit}
							sx={buttonModalWindowStyles}
						>
							{t('districts.forms.buttons.save')}
						</CoreButton>
					</Box>
				</Box>
			</Box>
		</CoreModal>
	);
};
