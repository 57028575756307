import { SurveyQuestionInterface } from '@families-link/shared';
import { useMuiModal } from '../../../_core/hooks';
import { useCallback } from 'react';
import { SurveyModal } from '../../components/modal-forms/survey-view.component';

interface OpenSurveyModalProps {
	questions: SurveyQuestionInterface[];
	modalTitle?: string;
}

export function useOpenSurveyModal({ questions, modalTitle = 'Survey' }: OpenSurveyModalProps) {
	const { openModal, closeModal } = useMuiModal(SurveyModal);

	const handleOpenSurveyModal = useCallback(() => {
		openModal({
			modalTitle,
			questions,
			onClose: closeModal,
		});
	}, [openModal, closeModal, modalTitle, questions]);

	return handleOpenSurveyModal;
}
