import React, { useMemo, useState } from 'react';
import { OrderEnum } from '@families-link/shared';
import { SortingState } from '@tanstack/react-table';

export const useSortingParams = () => {
	const [sortingArray, setSortingArray] = useState<SortingState>([]);
	const sortingParams = useMemo<{ orderField?: string; order?: OrderEnum }>(() => {
		const newSortingParams = sortingArray.length
			? {
					orderField: sortingArray[0].id,
					order: sortingArray[0].desc ? OrderEnum.DESC : OrderEnum.ASC,
			  }
			: {};
		return newSortingParams;
	}, [sortingArray]);
	const handleSortingChange = (sorting: SortingState) => {
		setSortingArray(sorting);
	};

	return {
		sortingArray,
		sortingParams,
		handleSortingChange,
	};
};
