import { IsArray, IsNotEmpty, Length, ValidateNested } from 'class-validator';
import { CreateSurveyQuestionDto } from './create-survey-quetion.dto';
import { Type } from 'class-transformer';

export class CreateSurveyTemplateDto {
	@IsNotEmpty()
	districtId: string;

	@IsNotEmpty()
	@Length(1)
	title: string;

	@IsArray()
	@ValidateNested({ each: true })
	@Type(() => CreateSurveyQuestionDto)
	questions?: Array<CreateSurveyQuestionDto>;

	language?: string | null;
}
