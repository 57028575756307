import { FC, useState } from 'react';
import { Container } from '@mui/material';
import { useInitiateState } from '../../../_core/hooks';
import { CreateSurveyTemplateDto, UpdateSurveyTemplateDto } from '@families-link/shared';
import { SurveyTemplateForm } from '../../components/modal-forms';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCreateSurveyTemplateMutation, useUpdateSurveyTemplateMutation } from '../../../../store/api/survey.api';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { getLocalStorageDistrictId } from '../../../_core/utils';
import { DistrictRoutes } from '../../../district/constants';

export const SurveyTemplateActionPage: FC = () => {
	useInitiateState('district');
	const [t] = useTranslation();
	const navigate = useNavigate();

	const location = useLocation();
	const { data } = location.state || {};

	const { enqueueSnackbar } = useSnackbar();
	const showToast = (message: string, variant: 'error' | 'default' | 'success' | 'warning' | 'info' | undefined) => {
		enqueueSnackbar(message, {
			variant,
		});
	};

	const [createSurveyTemplate] = useCreateSurveyTemplateMutation();
	const [updateSurveyTemplate] = useUpdateSurveyTemplateMutation();
	let handleSubmit;

	const { surveyTemplateId } = useParams();
	const mode = !!surveyTemplateId;

	if (surveyTemplateId) {
		handleSubmit = async (values: UpdateSurveyTemplateDto): Promise<void> => {
			const updateSurveyTemplateData: UpdateSurveyTemplateDto = { ...values };
			try {
				await updateSurveyTemplate({ id: surveyTemplateId, data: updateSurveyTemplateData }).unwrap();
				showToast('Survey template was successfully updated', 'success');
				navigate(DistrictRoutes.SurveyTemplates.Root.replace(':districtId', getLocalStorageDistrictId() || ''));
			} catch (error: any) {
				console.log(error);
				showToast('Survey template updating is failed', 'error');
			}
		};
	} else {
		handleSubmit = async (values: CreateSurveyTemplateDto): Promise<void> => {
			const createSurveyTemplateData: CreateSurveyTemplateDto = { ...values };
			try {
				await createSurveyTemplate(createSurveyTemplateData).unwrap();
				showToast('Survey template was successfully created', 'success');
				navigate(DistrictRoutes.SurveyTemplates.Root.replace(':districtId', getLocalStorageDistrictId() || ''));
			} catch (error: any) {
				console.log(error);
				showToast('Survey template creating is failed', 'error');
			}
		};
	}

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<SurveyTemplateForm submitAction={handleSubmit} defaultValues={mode ? data : undefined} mode={mode} />
		</Container>
	);
};
