import { EngagementDto, FamilyDto } from '@families-link/shared';
import { FC, useCallback, useMemo } from 'react';
import { CoreTableActionsCell, CellAction } from '../../../_core/components/_ui/core-table';
import { EngagementsTableHandlers } from './engagements-table.component';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { DeleteIcon, EditIcon, EyeIcon, SendSmsIcon, ShareIcon } from '../../../_core/components/icons';
import { ArchiveIcon } from '../../../_core/constants/icons.constants';

export interface EngagementsTableActionsProps extends EngagementsTableHandlers {
	engagement: EngagementDto;
}

export const EngagementsTableActions: FC<EngagementsTableActionsProps> = (props) => {
	const { engagement, onDelete, onEdit, onView, onArchive, onSendEmail, onSendSMS, onUnarchive } = props;

	const handleDeleteEngagement = useCallback(() => {
		if (!engagement) {
			return;
		}

		onDelete?.(engagement);
	}, [onDelete, engagement]);

	const handleEditEngagement = useCallback(() => {
		if (!engagement) {
			return;
		}

		onEdit?.(engagement);
	}, [onEdit, engagement]);

	const handleViewEngagement = useCallback(() => {
		if (!engagement) {
			return;
		}

		onView?.(engagement);
	}, [onView, engagement]);

	const handleSendEmailEngagement = useCallback(() => {
		if (!engagement) {
			return;
		}

		onSendEmail?.(engagement);
	}, [onSendEmail, engagement]);

	const handleSendSMSEngagement = useCallback(() => {
		if (!engagement) {
			return;
		}

		onSendSMS?.(engagement);
	}, [onSendSMS, engagement]);

	const handleArchiveEngagement = useCallback(() => {
		if (!engagement) {
			return;
		}

		if (engagement.archivedAt) {
			onUnarchive?.(engagement);
		} else {
			onArchive?.(engagement);
		}
	}, [onArchive, onUnarchive, engagement]);

	const actions = useMemo<CellAction[]>(
		() => [
			{
				id: 'view',
				type: 'button',
				props: {
					children: <EyeIcon sx={{ fontSize: 30 }} color="primary" />,
					onClick: handleViewEngagement,
				},
			},
			{
				id: 'more',
				type: 'menu',
				props: {
					options: [
						{
							id: 'email',
							label: 'Share by email',
							icon: <ShareIcon color="primary" />,
							onClick: handleSendEmailEngagement,
							disabled: true,
						},
						{
							id: 'sms',
							label: 'Send SMS',
							icon: <SendSmsIcon color="primary" />,
							onClick: handleSendSMSEngagement,
							disabled: true,
						},
						{
							id: 'archive',
							label: engagement.archivedAt ? 'Unarchive' : 'Archive',
							icon: <ArchiveIcon color="primary" />,
							onClick: handleArchiveEngagement,
						},
						{
							id: 'edit',
							label: 'Edit',
							icon: <EditIcon color="primary" />,
							onClick: handleEditEngagement,
						},
						{
							id: 'delete',
							label: 'Delete',
							icon: <DeleteIcon color="primary" />,
							onClick: handleDeleteEngagement,
						},
					],
				},
			},
		],
		[
			handleViewEngagement,
			handleEditEngagement,
			handleDeleteEngagement,
			handleSendEmailEngagement,
			handleSendSMSEngagement,
		]
	);

	return <CoreTableActionsCell actions={actions} />;
};
