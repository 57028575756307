import { CustomFieldDto, CustomFieldInfoDto, DefaultFieldInfoDto, OrderEnum } from '@families-link/shared';
import api from '.';

const customRequirementsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getAllFields: builder.query<
			CustomFieldDto[],
			{ districtId: string; sortBy?: string; sortOrder?: OrderEnum.ASC | OrderEnum.DESC }
		>({
			query: ({ districtId, sortBy, sortOrder }) => ({
				url: 'custom-fields',
				params: { districtId: districtId, sortBy, sortOrder },
			}),
		}),

		createCustomField: builder.mutation<CustomFieldInfoDto, CustomFieldInfoDto>({
			query: (newCustomField) => ({
				url: 'custom-fields',
				method: 'POST',
				body: newCustomField,
			}),
		}),

		updateCustomField: builder.mutation<CustomFieldInfoDto, { id: string; updateData: Partial<CustomFieldInfoDto> }>({
			query: ({ id, updateData }) => ({
				url: `custom-fields/${id}`,
				method: 'PUT',
				body: updateData,
			}),
		}),

		deleteCustomField: builder.mutation<void, string>({
			query: (id) => ({
				url: `custom-fields/${id}`,
				method: 'DELETE',
			}),
		}),
		getAllDefaultFields: builder.query<
			DefaultFieldInfoDto[],
			{ sortBy?: string; sortOrder?: OrderEnum.ASC | OrderEnum.DESC }
		>({
			query: ({ sortBy, sortOrder }) => ({
				url: 'default-fields',
				params: { sortBy, sortOrder },
			}),
		}),
	}),
	overrideExisting: false,
});

export default customRequirementsApi;
export const {
	useGetAllFieldsQuery,
	useCreateCustomFieldMutation,
	useUpdateCustomFieldMutation,
	useDeleteCustomFieldMutation,
	useGetAllDefaultFieldsQuery,
} = customRequirementsApi;
