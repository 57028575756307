import { SxProps, Theme } from '@mui/material';

export const successCardStyles: SxProps<Theme> = [
  {
    width: '100%',
    backgroundColor: (theme) => theme.colors.green,
    p: 1
  },
  ({ breakpoints }) => ({
    [breakpoints.down('sm')]: {
      width: '60%',
      p: 0.5
    },
  }),

];

export const cardActionsStyles: SxProps<Theme> = [
  {
    p: 1,
    paddingRight: 2,
    justifyContent: 'space-between'
  },
  ({ breakpoints }) => ({
    [breakpoints.down('sm')]: {
      p: 0.5,
      paddingRight: 1
    },
  }),

];

export const textBoxStyles: SxProps<Theme> = [
  {
    display: 'flex',
    flexDirection: 'column',
    px: 0.5
  }
];

export const headerStyles: SxProps<Theme> = [
  {
    color: (theme) => theme.colors.white,
    fontWeight: 'bold'
  },
  ({ breakpoints }) => ({
    [breakpoints.down('sm')]: {
      fontWeight: 'normal'
    },
  }),
];

export const messageStyles: SxProps<Theme> = [
  {
    color: (theme) => theme.colors.white,
    fontSize: '0.9rem'
  }
];

export const closeButtonStyles: SxProps<Theme> = [
  {
    color: (theme) => theme.colors.white,
  }
]

