import { IsNotEmpty, IsOptional, IsString, Length } from 'class-validator';

export class ProviderInfoDto {
	@IsString()
	@Length(1, 100)
	@IsNotEmpty({ message: 'Full name is required' })
	fullName: string;

	@IsNotEmpty({ message: 'Target languages are required' })
	targetLanguage: string | string[];

	@IsNotEmpty({ message: 'Verification type is required' })
	verificationType: boolean;

	@IsNotEmpty({ message: 'Work Status is required' })
	workStatus: string;

	@IsNotEmpty({ message: 'Service type is required' })
	serviceType: string;

	districtId: string;

	createdById: string;

	@IsOptional()
	imageUrl?: string;
}
