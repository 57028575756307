export enum FieldDataTypeEnum {
	ShortText = 'Short text',
	Options = 'Options',
	SystemStatus = 'System status',
	DateDuration = 'Date duration',
	LongText = 'Long text',
	File = 'Files',
	MultiSelect = 'MultiSelect',
}

export const FieldDataTypeArray = [
	FieldDataTypeEnum.ShortText,
	FieldDataTypeEnum.Options,
	FieldDataTypeEnum.DateDuration,
	FieldDataTypeEnum.LongText,
	FieldDataTypeEnum.File,
	FieldDataTypeEnum.MultiSelect,
];
