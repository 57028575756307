import { FC } from 'react';
import { TableListQueryResponse } from '../../../_core/interfaces/table-list-query.interface';
import { ProviderDto } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { CoreTable, CoreTableEmptyBody } from '../../../_core/components/_ui/core-table';
import { useProvidersTableColumns } from '../../hooks/providers-table-columns.hook';

export interface ProvidersTableHandlers {
	onDelete(provider: ProviderDto): void;
	onEdit(provider: ProviderDto): void;
	onView(provider: ProviderDto): void;
}

type ProvidersTableProps = TableListQueryResponse<ProviderDto> & ProvidersTableHandlers;

export const ProvidersTable: FC<ProvidersTableProps> = (props) => {
	const { data, sorting, onSortingChange, loading, hasMore, fetchMore, scrollRef, onDelete, onEdit, onView } = props;

	const [t] = useTranslation();

	const columns = useProvidersTableColumns({
		onDelete,
		onEdit,
		onView,
	});

	const table = useReactTable<ProviderDto>({
		data,
		columns,
		state: {
			sorting,
		},
		onSortingChange,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<CoreTable
			px={0}
			ref={scrollRef}
			loading={loading}
			hasMore={hasMore}
			emptyListComponent={<CoreTableEmptyBody description={t('providers.no-providers')} />}
			fetchMore={fetchMore}
			rowModel={table.getRowModel()}
			headerGroups={table.getHeaderGroups()}
		/>
	);
};
