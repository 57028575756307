import {
	EngagementDto,
	PageDto,
	SourceFilter,
	PageOptionsDto,
	LanguageFilter,
	ProvidersFilter,
} from '@families-link/shared';
import api from '.';

const engagementsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getEngagementsList: builder.query<
			PageDto<EngagementDto>,
			PageOptionsDto &
				SourceFilter &
				LanguageFilter &
				ProvidersFilter & { district?: string | null; start?: string | null; end?: string | null; archived?: boolean }
		>({
			query: ({ page, take, orderField, order, source, languages, providers, district, start, end, archived }) => ({
				url: `engagements`,
				params: { page, take, orderField, order, source, languages, providers, district, start, end, archived },
			}),
		}),
		getEngagement: builder.query<EngagementDto, { id?: string | null }>({
			query: ({ id }) => ({
				url: `engagements/${id}`,
			}),
		}),
		createEngagement: builder.mutation<EngagementDto, FormData>({
			query: (data) => ({
				url: `engagements`,
				method: 'POST',
				body: data,
			}),
		}),
		updateEngagement: builder.mutation<EngagementDto, { id?: string; data: FormData }>({
			query: ({ id, data }) => ({
				url: `engagements/${id}`,
				method: 'PUT',
				body: data,
			}),
		}),
		deleteEngagement: builder.mutation<EngagementDto, { id: string }>({
			query: ({ id }) => ({
				url: `engagements/${id}`,
				method: 'DELETE',
			}),
		}),
		archiveEngagement: builder.mutation<EngagementDto, { id: string }>({
			query: ({ id }) => ({
				url: `engagements/${id}/archive`,
				method: 'PUT',
			}),
		}),
		unarchiveEngagement: builder.mutation<EngagementDto, { id: string }>({
			query: ({ id }) => ({
				url: `engagements/${id}/unarchive`,
				method: 'PUT',
			}),
		}),
		getEngagementsByFamilyId: builder.query<
			PageDto<EngagementDto>,
			PageOptionsDto & {
				familyId?: string;
			}
		>({
			query: ({ familyId, page, take, orderField, order }) => ({
				url: `engagements/family/${familyId}`,
				params: {
					page,
					take,
					orderField,
					order,
				},
			}),
		}),
		getEngagementsByProviderId: builder.query<
			PageDto<EngagementDto>,
			PageOptionsDto & {
				providerId?: string;
			}
		>({
			query: ({ providerId, page, take, orderField, order }) => ({
				url: `engagements/provider/${providerId}`,
				params: {
					page,
					take,
					orderField,
					order,
				},
			}),
		}),
		exportEngagementsToCSV: builder.mutation<
			{ fileUrl: string },
			SourceFilter &
				LanguageFilter &
				ProvidersFilter & { district?: string; start?: string | null; end?: string | null; fields?: string }
		>({
			query: ({ source, languages, providers, district, start, end, fields }) => ({
				url: `engagements/export/csv`,
				params: { source, languages, providers, district, start, end, fields },
				responseType: 'json',
			}),
		}),
	}),
});

export default engagementsApi;

export const {
	useGetEngagementsListQuery,
	useGetEngagementQuery,
	useCreateEngagementMutation,
	useUpdateEngagementMutation,
	useDeleteEngagementMutation,
	useGetEngagementsByFamilyIdQuery,
	useGetEngagementsByProviderIdQuery,
	useExportEngagementsToCSVMutation,
	useArchiveEngagementMutation,
	useUnarchiveEngagementMutation,
} = engagementsApi;
