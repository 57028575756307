import {
	CreateUserDto,
	DistrictDto,
	PageDto,
	PageOptionsDto,
	SourceFilter,
	UpdateUserDataDto,
	UpdateUserDto,
	UserDto,
} from '@families-link/shared';
import api from '.';

const usersApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getUserActiveStatusById: builder.query<boolean, string>({
			query: (id) => ({
				url: `users/is-active/${id}`,
			}),
			extraOptions: {
				skipAuth: true,
			},
		}),
		getUserDistrictById: builder.query<string | undefined, string | undefined>({
			query: (id) => ({
				url: `users/district-id/${id}`,
				responseHandler: 'text',
			}),
			transformResponse: (response: string) => response,
		}),
		getUserById: builder.query<UserDto, string>({
			query: (id) => ({
				url: `users/user/${id}`,
			}),
		}),
		getUsersList: builder.query<PageDto<DistrictDto>, PageOptionsDto & SourceFilter & { district: string }>({
			query: ({ page, take, orderField, order, source, district }) => ({
				url: `users`,
				params: { page, take, orderField, order, source, district },
			}),
		}),
		updateCurrentUser: builder.mutation<UserDto, { id: string; data: UpdateUserDataDto }>({
			query: ({ id, data }) => ({
				url: `users/user/${id}`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['UserProfile'],
		}),
		createUser: builder.mutation<UserDto, CreateUserDto>({
			query: (data) => ({
				url: `users`,
				method: 'POST',
				body: data,
			}),
		}),
		updateUser: builder.mutation<UserDto, { id: string; data: UpdateUserDto }>({
			query: ({ id, data }) => ({
				url: `users/${id}`,
				method: 'PUT',
				body: data,
			}),
		}),
		deleteUser: builder.mutation<UserDto, { id: string }>({
			query: ({ id }) => ({
				url: `users/${id}`,
				method: 'DELETE',
			}),
		}),
	}),
});

export default usersApi;

export const {
	useGetUserByIdQuery,
	useGetUsersListQuery,
	useUpdateCurrentUserMutation,
	useUpdateUserMutation,
	useCreateUserMutation,
	useDeleteUserMutation,
	useGetUserActiveStatusByIdQuery,
	useGetUserDistrictByIdQuery,
} = usersApi;
