import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from '../../../store';
import { DistrictDto } from '@families-link/shared';
import districtsApi from '../../../store/api/district.api';

export type DistrictState = { currentDistrict: DistrictDto | null };

export const initialState = {
	currentDistrict: null,
};

export const districtSlice = createSlice({
	name: 'district',
	initialState,
	reducers: {
		updateCurrentDistrict: (state: DistrictState, action) => {
			state.currentDistrict = action.payload;
		},
		clearCurrentDistrict: (state: DistrictState) => {
			state.currentDistrict = null;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(districtsApi.endpoints.getDistrict.matchFulfilled, (state: DistrictState, action) => {
			state.currentDistrict = action.payload;
		});
	},
});

export const { updateCurrentDistrict, clearCurrentDistrict } = districtSlice.actions;

export const useCurrentDistrictSelector = () => useAppSelector((state) => state.district.currentDistrict);
