import React, { FC } from 'react';
import { CoreSelect, CoreSelectProps } from '../core-select';
import { MenuItem } from '@mui/material';

const generateDurationOptions = () => {
	const options = [];
	const maxMinutes = 12 * 60;
	for (let i = 5; i <= maxMinutes; i += 5) {
		const hours = Math.floor(i / 60);
		const minutes = i % 60;
		let label = '';
		if (hours > 0) {
			label += `${hours} hr`;
		}
		if (minutes > 0) {
			label += `${hours > 0 ? ' ' : ''} ${minutes} min`;
		}
		options.push({ value: i * 60000, label });
	}
	return options;
};

const durationOptions = generateDurationOptions();

export const CoreDurationDropdown: FC<CoreSelectProps> = (props) => (
	<CoreSelect {...props} paperSx={[{ maxHeight: '50%' }]}>
		{durationOptions?.map((el: any) => (
			<MenuItem key={el.value} value={el.value}>
				{el.label}
			</MenuItem>
		))}
	</CoreSelect>
);
