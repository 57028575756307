import { IsEmail, IsEnum, IsOptional, IsPhoneNumber, Length, IsBoolean } from 'class-validator';
import { UserRolesEnum } from '../../enums';

export class CreateUserDto {
	@IsEmail()
	email: string;

	@IsOptional()
	@Length(8, 36)
	password?: string;

	@IsOptional()
	@IsPhoneNumber()
	phoneNumber?: string;

	@Length(1, 100)
	name: string;

	@IsOptional()
	@IsEmail()
	googleEmail?: string;

	@IsEnum(UserRolesEnum)
	role: string;

	@IsBoolean()
	active: boolean;

	@IsOptional()
	districtId?: string;
}
