import {
	CreateSurveyAnswerDto,
	CreateSurveyTemplateDto,
	EmailDto,
	EngagementSurveyDto,
	FamilyWithSurveyDto,
	PageDto,
	PageOptionsDto,
	SurveyAnswerDto,
	SurveyTemplateDto,
	UpdateSurveyTemplateDto,
	SortingOptionsDto,
} from '@families-link/shared';
import api from '.';

const surveysApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getSurveyTemplateById: builder.query<SurveyTemplateDto, string>({
			query: (id) => ({
				url: `surveys/templates/${id}`,
			}),
		}),
		getAllSurveyTemplatesList: builder.query<SurveyTemplateDto[], string>({
			query: (districtId) => ({
				url: `surveys/templates/by-district`,
				params: { district: districtId },
			}),
		}),
		getSurveyTemplatesList: builder.query<PageDto<SurveyTemplateDto>, PageOptionsDto & { district: string }>({
			query: ({ page, take, orderField, order, district }) => ({
				url: `surveys/templates`,
				params: { page, take, orderField, order, district },
			}),
		}),
		createSurveyTemplate: builder.mutation<SurveyTemplateDto, CreateSurveyTemplateDto>({
			query: (data) => ({
				url: `surveys/templates`,
				method: 'POST',
				body: data,
			}),
		}),
		updateSurveyTemplate: builder.mutation<SurveyTemplateDto, { id: string; data: UpdateSurveyTemplateDto }>({
			query: ({ id, data }) => ({
				url: `surveys/templates/${id}`,
				method: 'PUT',
				body: data,
			}),
		}),
		deleteSurveyTemplate: builder.mutation<SurveyTemplateDto, { id: string }>({
			query: ({ id }) => ({
				url: `surveys/templates/${id}`,
				method: 'DELETE',
			}),
		}),
		getEngagementSurveyById: builder.query<EngagementSurveyDto, string>({
			query: (id) => ({
				url: `surveys/${id}`,
			}),
		}),
		checkExistEngagementSurvey: builder.query<EngagementSurveyDto, string>({
			query: (id) => ({
				url: `surveys/engagement/${id}`,
			}),
			providesTags: ['Survey'],
		}),
		createEngagementSurvey: builder.mutation<EngagementSurveyDto, EngagementSurveyDto>({
			query: ({ surveyTemplateId, engagementId }) => ({
				url: `surveys/connect-survey/${surveyTemplateId}/${engagementId}`,
				method: 'POST',
			}),
			invalidatesTags: ['Survey'],
		}),
		sendSurveyToFamilies: builder.mutation<void, { engagementId: string }>({
			query: ({ engagementId }) => ({
				url: `surveys/send-surveys/${engagementId}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Survey'],
		}),
		writeSurveyAnswers: builder.mutation<void, CreateSurveyAnswerDto>({
			query: (data) => ({
				url: `surveys/answers`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: ['SurveyAnswer'],
		}),
		getSurveyAnswers: builder.query<SurveyAnswerDto, { surveyId: string; familyId: string }>({
			query: ({ surveyId, familyId }) => ({
				url: `surveys/answers/${surveyId}/${familyId}`,
			}),
			providesTags: ['SurveyAnswer'],
		}),
		getListEngagementFamiliesWithSurvey: builder.query<FamilyWithSurveyDto[], SortingOptionsDto & { id: string }>({
			query: ({ id, orderField, order }) => ({
				url: `surveys/family-list/${id}`,
				params: { orderField, order },
			}),
			providesTags: ['Survey', 'SurveyAnswer'],
		}),
		sendSurveyByEmail: builder.mutation<void, EmailDto & { surveyId: string }>({
			query: (data) => ({
				url: `surveys/send-to-family/${data.surveyId}`,
				method: 'PATCH',
				body: data,
			}),
		}),
		getSurveyLink: builder.query<string, { surveyId: string; familyId: string }>({
			query: ({ surveyId, familyId }) => ({
				url: `surveys/generate-link/${surveyId}/${familyId}`,
				transformResponse: (response: any) => response,
				responseHandler: (response) => response.text(),
			}),
		}),
	}),
});

export default surveysApi;

export const {
	useGetSurveyTemplatesListQuery,
	useGetSurveyTemplateByIdQuery,
	useCreateSurveyTemplateMutation,
	useUpdateSurveyTemplateMutation,
	useDeleteSurveyTemplateMutation,
	useGetEngagementSurveyByIdQuery,
	useCreateEngagementSurveyMutation,
	useCheckExistEngagementSurveyQuery,
	useGetAllSurveyTemplatesListQuery,
	useSendSurveyToFamiliesMutation,
	useWriteSurveyAnswersMutation,
	useGetSurveyAnswersQuery,
	useLazyGetSurveyAnswersQuery,
	useGetListEngagementFamiliesWithSurveyQuery,
	useLazyGetListEngagementFamiliesWithSurveyQuery,
	useSendSurveyByEmailMutation,
	useLazyGetSurveyLinkQuery,
} = surveysApi;
