import { useAppDispatch } from '../../../store';
import { clearCurrentDistrict } from '../../district/store/district.slice';
import { clearCurrentEngagement } from '../../engagement/store/engagement.slice';
import { clearCurrentFamily } from '../../family/store/family.slice';
import { clearCurrentProvider } from '../../providers/store/provider.slice';
import {
	removeLocalStorageDistrictId,
	removeLocalStorageEngagementId,
	removeLocalStorageFamilyId,
	removeLocalStorageProviderId,
	removeLocalStorageSurveyTemplateId,
} from '../utils';

export const useClearStore = () => {
	const dispatch = useAppDispatch();
	return () => {
		dispatch(clearCurrentDistrict());
		removeLocalStorageDistrictId();

		dispatch(clearCurrentFamily());
		removeLocalStorageFamilyId();

		dispatch(clearCurrentProvider());
		removeLocalStorageProviderId();

		dispatch(clearCurrentEngagement());
		removeLocalStorageEngagementId();

		removeLocalStorageSurveyTemplateId();
	};
};
