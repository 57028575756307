import { FC } from 'react';
import { TableListQueryResponse } from '../../../_core/interfaces/table-list-query.interface';
import { FieldDto } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { CoreTable, CoreTableEmptyBody } from '../../../_core/components/_ui/core-table';
import { useFieldsTableColumns } from '../../hooks/custom-requirements-columns.hook';

export interface CustomRequirementsTableHandlers {
	onDelete(field: FieldDto): void;
	onEdit(field: FieldDto): void;
}

type CustomRequirementsTableProps = TableListQueryResponse<FieldDto> & CustomRequirementsTableHandlers;

export const CustomRequirementsTable: FC<CustomRequirementsTableProps> = (props) => {
	const { data, sorting, onSortingChange, loading, hasMore, fetchMore, scrollRef, onDelete, onEdit } = props;

	const [t] = useTranslation();

	const columns = useFieldsTableColumns({
		onDelete,
		onEdit,
	});

	const table = useReactTable<FieldDto>({
		data,
		columns,
		state: {
			sorting,
		},
		onSortingChange,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<CoreTable
			px={0}
			ref={scrollRef}
			loading={loading}
			hasMore={hasMore}
			emptyListComponent={<CoreTableEmptyBody description={t('custom-fields.no-custom')} />}
			fetchMore={fetchMore}
			rowModel={table.getRowModel()}
			headerGroups={table.getHeaderGroups()}
		/>
	);
};
