export enum DefaultFieldDataTypeEnum {
	ShortText = 'Short text',
	LongText = 'Long text',
	Options = 'Options',
	SystemStatus = 'System status',
	DateDuration = 'Date duration',
}

export const DefaultFieldDataTypeArray = [
	DefaultFieldDataTypeEnum.ShortText,
	DefaultFieldDataTypeEnum.LongText,
	DefaultFieldDataTypeEnum.Options,
	DefaultFieldDataTypeEnum.SystemStatus,
	DefaultFieldDataTypeEnum.DateDuration,
];
