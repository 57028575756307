import React, { useRef, useState, useCallback, useMemo, useEffect } from 'react';
import AvatarEditor from 'react-avatar-editor';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Slider, Typography } from '@mui/material';
import { CoreButton, CoreIconButton } from '../_ui/core-button';
import { useDropzone } from 'react-dropzone';
import { ALLOWED_IMAGE_MIME_TYPES, MAX_FILE_SIZE } from '@families-link/shared';
import { UserIcon } from '../../constants/icons.constants';

interface UploadPhotoProps {
	onFileChange: (file: File | null) => void;
	file?: string | null;
	editable?: boolean;
}

const UploadPhoto: React.FC<UploadPhotoProps> = ({ onFileChange, file, editable }) => {
	const [image, setImage] = useState<File | undefined>(undefined);

	const [imageSrc, setImageSrc] = useState<typeof file | null>(file);

	const [imageScale, setImageScale] = useState<number>(1);
	const [error, setError] = useState<string | null>(null);

	const [editorKey, setEditorKey] = useState<number>(0);

	const editorRef = useRef<AvatarEditor>(null);

	const validateFile = (file: File) => {
		if (!ALLOWED_IMAGE_MIME_TYPES.includes(file.type)) {
			return 'Invalid file type. Only JPEG and PNG are allowed.';
		}
		if (file.size > MAX_FILE_SIZE) {
			return 'File size exceeds 10 MB.';
		}

		return null;
	};

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length > 0) {
			const selectedFile = e.target.files[0];
			const validationError = validateFile(selectedFile);

			if (validationError) {
				setError(validationError);
				onFileChange(null);
			} else {
				setError(null);
				setImage(selectedFile);
				setImageSrc(URL.createObjectURL(selectedFile));
				onFileChange(selectedFile);
				setEditorKey((prevKey) => prevKey + 1);
			}
		}
	};

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			if (acceptedFiles && acceptedFiles.length > 0) {
				const selectedFile = acceptedFiles[0];
				const validationError = validateFile(selectedFile);
				if (validationError) {
					setError(validationError);
					onFileChange(null);
				} else {
					setError(null);
					setImage(selectedFile);
					setImageSrc(URL.createObjectURL(selectedFile));
					onFileChange(selectedFile);
					setEditorKey((prevKey) => prevKey + 1);
				}
			}
		},
		[onFileChange]
	);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: {
			'image/*': ['.jpeg', '.jpg', '.png'],
		},
		maxFiles: 1,
	});

	const handleRemove = useCallback(() => {
		setImage(undefined);

		onFileChange(null);
		setImageSrc(null);
		setError(null);
		setEditorKey((prevKey) => prevKey + 1);
	}, [file, onFileChange]);

	const handleClick = () => {
		document.getElementById('file-input')?.click();
	};

	const handleScaleChange = (event: Event, newValue: number | number[]) => {
		setImageScale(newValue as number);
	};

	const saveScaledImage = useCallback(() => {
		if (editorRef.current) {
			const canvas = editorRef.current.getImageScaledToCanvas();

			canvas.toBlob((blob) => {
				if (blob) {
					const scaledImage = new File([blob], image?.name || 'scaled-image.jpg', {
						type: blob.type,
					});
					onFileChange(scaledImage);
				}
			}, 'image/jpeg');
		} else {
			setError('Editor is not initialized.');
		}
	}, [image, onFileChange, handleRemove]);

	const avatarSrc = useMemo(() => {
		if (imageSrc) {
			return imageSrc;
		} else {
			return null;
		}
	}, [imageSrc, file]);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				minHeight: 236,
				width: 236,
				rowGap: 1,
				border: '2px dashed #D5D6DA',
				borderRadius: '12px',
				backgroundColor: (theme) => theme.colors.grayLight,
				overflow: 'hidden',
			}}
		>
			{image ? (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						mb: 2,
						width: '100%',
						height: '100%',
						overflow: 'hidden',
					}}
				>
					{image ? (
						<>
							<AvatarEditor
								key={editorKey}
								ref={editorRef}
								image={image}
								width={174}
								height={174}
								border={50}
								borderRadius={100}
								color={[255, 255, 255, 0.6]}
								scale={imageScale}
								rotate={0}
								style={{ objectFit: 'cover' }}
								onImageChange={saveScaledImage}
							/>
							<Slider
								value={imageScale}
								onChange={handleScaleChange}
								min={1}
								max={3}
								step={0.1}
								aria-labelledby="zoom-slider"
								valueLabelDisplay="auto"
								sx={{ width: '90%' }}
							/>
						</>
					) : (
						<Box
							{...getRootProps()}
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								minHeight: 174,
								width: 174,
								cursor: 'pointer',
								borderRadius: '12px',
								backgroundColor: 'var(--blue-8-opacity, rgba(36, 57, 106, 0.08))',
								mt: 2,
								mb: 2,
								overflow: 'hidden',
							}}
						>
							<UserIcon style={{ fontSize: 48 }} />
						</Box>
					)}

					<Box sx={{ display: 'flex', flexDirection: 'row', px: 2, alignItems: 'center' }}>
						<CoreButton
							sx={{ width: '174px', height: '36px', fontSize: '14px' }}
							variant="secondary"
							onClick={handleClick}
						>
							Choose Photo
						</CoreButton>
						{image && (
							<CoreIconButton
								onClick={handleRemove}
								sx={{
									width: 40,
									borderRadius: '16px',
									height: 40,
									ml: 1,
									color: ({ colors }) => colors.darkGray,
									border: ({ colors }) => `1px solid ${colors.blueTransparent}`,
								}}
							>
								<CloseIcon />
							</CoreIconButton>
						)}
					</Box>
					<input
						id="file-input"
						type="file"
						accept="image/*"
						style={{ display: 'none' }}
						onChange={handleImageChange}
					/>
					<input {...getInputProps()} />
				</Box>
			) : (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						mb: 2,
						cursor: 'pointer',
						width: '100%',
						height: '100%',
						overflow: 'hidden',
					}}
				>
					{avatarSrc ? (
						<>
							<AvatarEditor
								key={editorKey}
								ref={editorRef}
								image={avatarSrc}
								width={174}
								height={174}
								border={50}
								borderRadius={100}
								color={[255, 255, 255, 0.6]}
								scale={imageScale}
								rotate={0}
								style={{ objectFit: 'cover' }}
							/>
						</>
					) : (
						<Box
							{...getRootProps()}
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								minHeight: 174,
								width: 174,
								cursor: 'pointer',
								borderRadius: '12px',
								backgroundColor: 'var(--blue-8-opacity, rgba(36, 57, 106, 0.08))',
								mt: 2,
								mb: 2,
							}}
						>
							<UserIcon style={{ fontSize: 48 }} />
						</Box>
					)}
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							px: 2,
							alignItems: 'center',
						}}
					>
						<CoreButton
							sx={{ width: '174px', height: '36px', fontSize: '14px' }}
							variant="secondary"
							onClick={handleClick}
						>
							Choose Photo
						</CoreButton>
						<input
							id="file-input"
							type="file"
							accept="image/*"
							style={{ display: 'none' }}
							onChange={handleImageChange}
						/>
						{(imageSrc || image) && (
							<CoreIconButton
								onClick={handleRemove}
								sx={{
									width: 40,
									borderRadius: '16px',
									height: 40,
									ml: 1,
									color: ({ colors }) => colors.darkGray,
									border: ({ colors }) => `1px solid ${colors.blueTransparent}`,
								}}
							>
								<CloseIcon />
							</CoreIconButton>
						)}
					</Box>
				</Box>
			)}

			{error && (
				<Typography color="error" variant="caption" sx={{ textAlign: 'center' }}>
					{error}
				</Typography>
			)}
		</Box>
	);
};

export default UploadPhoto;
