import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from '../../../store';
import { FamilyDto } from '@families-link/shared';
import familiesApi from '../../../store/api/families.api';

export type FamilyState = { currentFamily: FamilyDto | null };

export const initialState = {
	currentFamily: null,
};

export const familySlice = createSlice({
	name: 'family',
	initialState,
	reducers: {
		updateCurrentFamily: (state: FamilyState, action) => {
			state.currentFamily = action.payload;
		},
		clearCurrentFamily: (state: FamilyState) => {
			state.currentFamily = null;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(familiesApi.endpoints.getFamily.matchFulfilled, (state: FamilyState, action) => {
			state.currentFamily = action.payload;
		});
	},
});

export const { updateCurrentFamily, clearCurrentFamily } = familySlice.actions;

export const useCurrentFamilySelector = () => useAppSelector((state) => state.family.currentFamily);
