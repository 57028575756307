import { createAsyncThunk } from '@reduxjs/toolkit';
import engagementsApi from '../../../../store/api/engagements.api';
import { getLocalStorageEngagementId } from '../../../_core/utils';

export const initiateEngagement = createAsyncThunk('engagements/initiateEngagement', async (_, { dispatch }) => {
	const currentEngagementId = getLocalStorageEngagementId();
	if (currentEngagementId) {
		await dispatch(
			engagementsApi.endpoints.getEngagement.initiate({ id: currentEngagementId }, { forceRefetch: true })
		);
	}
});
