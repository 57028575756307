import { FC, useCallback, useState } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FamiliesDetailsTable } from './families-details-table/families-details-table.component';
import {
	EngagementSurveyDto,
	FamilyWithSurveyDto,
	SurveyAnswerDto,
	SurveyStateStatusEnum,
	SurveyTemplateDto,
} from '@families-link/shared';
import { useMuiModal, useSortingParams } from '../../../_core/hooks';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { useSnackbar } from 'notistack';
import {
	CreateSurveyFormModal,
	CreateSurveyFormModalProps,
} from '../../../survey/components/survey-tracking/modal-foms/create-survey-form.component';
import { FamilyDetails } from './family-details.component';
import { ResultsSurvey } from '../../../survey/components/survey-tracking/survey-results.component';

interface FamilyDetailsListProps {
	engagementId: string;
	itemsList?: FamilyWithSurveyDto[];
	engagementSurvey?: EngagementSurveyDto;
	isFetching: boolean;
	sendSurveyAction?: any;
	createSurveyAction?: any;
	sendSurveyByEmailAction?: any;
	surveyTemplatesList?: SurveyTemplateDto[];
	triggerSurveyLink: any;
	triggerSurveyAnswer: any;
	sortingArray: any;
	handleSortingChange: any;
}

export const FamilyDetailsList: FC<FamilyDetailsListProps> = (props) => {
	// const { data: families } = useGetAllFamiliesByListQuery({ families: familyIds });
	const {
		engagementId,
		isFetching,
		itemsList = [],
		engagementSurvey,
		sendSurveyAction,
		createSurveyAction,
		sendSurveyByEmailAction,
		surveyTemplatesList,
		triggerSurveyLink,
		triggerSurveyAnswer,
		sortingArray,
		handleSortingChange,
	} = props;
	const [t] = useTranslation();
	const [resultComponent, setResultComponent] = useState(false);
	const [currentFamilyWithSurvey, setCurrentFamilyWithSurvey] = useState<FamilyWithSurveyDto>();
	const [surveyAnswer, setSurveyAnswer] = useState<SurveyAnswerDto>();
	console.log(sortingArray);
	const { enqueueSnackbar } = useSnackbar();

	const showToast = (message: string, variant: 'error' | 'default' | 'success' | 'warning' | 'info' | undefined) => {
		enqueueSnackbar(message, {
			variant,
		});
	};

	const { openModal, closeModal, updateCurrentModal } = useMuiModal<CreateSurveyFormModalProps>(CreateSurveyFormModal);

	const handleSubmitCreate = async (values: any): Promise<void> => {
		try {
			await createSurveyAction({
				surveyTemplateId: values.surveyTemplate._id,
				engagementId,
			}).unwrap();
			showToast('Survey for this engagement was successfully created', 'success');
			closeModal();
		} catch (error: any) {
			updateCurrentModal({ formError: error });
		}
	};

	const handleSubmitSend = async (): Promise<void> => {
		try {
			await sendSurveyAction({
				engagementId,
			}).unwrap();
			showToast('Survey was successfully sended to families', 'success');
		} catch (error: any) {
			console.log(error);
			showToast('Survey sending was failed', 'error');
		}
	};

	const handleOpenModalCreate = useCallback(() => {
		openModal({
			surveyTemplatesList,
			submitAction: handleSubmitCreate,
			closeModal,
		});
	}, [openModal, closeModal, surveyTemplatesList, handleSubmitCreate]);

	const handleOnSendEmail = (familyWithSurvey: FamilyWithSurveyDto) => {
		try {
			sendSurveyByEmailAction({
				email: familyWithSurvey.family.email,
				surveyId: familyWithSurvey.survey?._id,
			}).unwrap();
			showToast('Survey was successfully sended to family by email', 'success');
		} catch (error) {
			console.log(error);
			showToast('Survey sending was failed', 'error');
		}
	};

	const handleOnSendSms = (familyWithSurvey: FamilyWithSurveyDto) => {
		console.log('send sms');
	};

	const handleOnManualEditing = async (familyWithSurvey: FamilyWithSurveyDto) => {
		try {
			const surveyLink = await triggerSurveyLink({
				surveyId: familyWithSurvey.survey?._id,
				familyId: familyWithSurvey.family._id,
			}).unwrap();
			window.open(surveyLink, '_blank');
		} catch (error) {
			console.log(error);
		}
	};

	const handleOnView = async (familyWithSurvey: FamilyWithSurveyDto) => {
		const data = await triggerSurveyAnswer({
			surveyId: familyWithSurvey.survey?._id,
			familyId: familyWithSurvey.family._id,
		}).unwrap();
		setSurveyAnswer(data);
		setCurrentFamilyWithSurvey(familyWithSurvey);
		setResultComponent(true);
	};

	const returnFromResults = () => {
		setSurveyAnswer(undefined);
		setCurrentFamilyWithSurvey(undefined);
		setResultComponent(false);
	};

	return (
		<Box sx={{ height: '100%' }}>
			{resultComponent ? (
				<Container
					maxWidth={false}
					sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px', position: 'relative' }}
				>
					<Box sx={{ height: '32%' }}>
						<Button
							onClick={returnFromResults}
							sx={{
								height: '35px',
								width: '35px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								minWidth: 0,
								position: 'absolute',
								top: '4px',
								left: '4px',
								zIndex: 10,
								borderRadius: '50%',
								fontSize: '20px',
								color: (theme) => theme.colors.grayBlue,
								'&:hover': { backgroundColor: (theme) => theme.colors.grayLight },
							}}
						>{`<`}</Button>
						<Box>
							<Typography variant="h6" sx={{ fontWeight: 'bold', marginY: '8px', marginLeft: '24px' }}>
								{t('families.family-contact')}
							</Typography>
						</Box>
						<FamilyDetails familyId={currentFamilyWithSurvey?.family._id} isEngagementPage />
					</Box>
					<ResultsSurvey surveyAnswer={surveyAnswer} />
				</Container>
			) : (
				<Container
					maxWidth={false}
					sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'flex-start',
							width: '100%',
							mt: 1,
						}}
					>
						<Box>
							<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
								{t('families.family-contact')}
							</Typography>
						</Box>
						<Box sx={{ ml: 'auto', mr: 1 }}>
							{engagementSurvey?.status === SurveyStateStatusEnum.Created ? (
								<CoreButton onClick={handleSubmitSend}>Send Survey</CoreButton>
							) : engagementSurvey?.status === SurveyStateStatusEnum.Delivered ? (
								<CoreButton disabled>Send Survey</CoreButton>
							) : (
								<CoreButton onClick={handleOpenModalCreate}>Choose Survey</CoreButton>
							)}
						</Box>
					</Box>

					<FamiliesDetailsTable
						data={itemsList ?? []}
						loading={isFetching}
						onSendEmail={handleOnSendEmail}
						onSendSms={handleOnSendSms}
						onEdit={handleOnManualEditing}
						onView={handleOnView}
						sorting={sortingArray}
						onSortingChange={handleSortingChange}
					/>
				</Container>
			)}
		</Box>
	);
};
