import { DistrictDto, FamilyDto, ProviderDto } from '@families-link/shared';
import { useCurrentDistrictSelector } from '../../district/store/district.slice';
import { useCurrentFamilySelector } from '../../family/store/family.slice';
import { useCurrentProviderSelector } from '../../providers/store/provider.slice';

export const useReplaceName = () => {
	const currentDistrict = useCurrentDistrictSelector() as DistrictDto | null;
	const currentFamily = useCurrentFamilySelector() as FamilyDto | null;
	const currentProvider = useCurrentProviderSelector() as ProviderDto | null;
	return (str: string) => {
		let result = str;
		if (currentDistrict) result = result.replace(':districtName', currentDistrict.name);
		if (currentFamily) result = result.replace(':familyName', currentFamily.fullName);
		if (currentProvider) result = result.replace(':providerName', currentProvider.fullName);
		return result;
	};
};
