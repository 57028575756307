import { FC } from 'react';
import { getLocalStorageEngagementId, getLocalStorageDistrictId } from '../../../_core/utils';
import { useGetEngagementQuery, useUpdateEngagementMutation } from '../../../../store/api/engagements.api';
import { useTranslation } from 'react-i18next';
import { Box, Container, IconButton, Typography } from '@mui/material';
import {
	DefaultFieldTypeStatusEnum,
	EmailStatusColorEnum,
	EmailStatusEnum,
	EngagementDto,
	FieldDataTypeEnum,
	UpdateEngagementDto,
	EngagementStatusColorEnum,
	ServiceTypeStatusEnum,
} from '@families-link/shared';
import { format, parseISO, parseJSON } from 'date-fns';
import { useGetAllFieldsQuery } from '../../../../store/api/customRequirements.api';
import { useGetAllFamiliesByDistrictIdQuery } from '../../../../store/api/families.api';
import { useGetAllProvidersQuery } from '../../../../store/api/providers.api';
import { useOpenModalForm } from '../../hooks/modal-forms/use-open-modal-form.hook';
import { EditIcon, DownloadIcon } from '../../../_core/constants/icons.constants';

interface EngagementDetailsProps {
	engagementId?: string;
}

export const EngagementDetails: FC<EngagementDetailsProps> = ({ engagementId: propEngagementId }) => {
	const engagementId = propEngagementId || getLocalStorageEngagementId();

	const { data: engagement, error, isLoading, refetch } = useGetEngagementQuery({ id: engagementId });

	const [t] = useTranslation();

	const currentDistrict = getLocalStorageDistrictId();

	const { data: fieldsListFromDistrict } = useGetAllFieldsQuery({ districtId: currentDistrict || '' });
	const { data: providersList } = useGetAllProvidersQuery({
		languages: undefined,
		sortBy: undefined,
		sortOrder: undefined,
		searchQuery: undefined,
	});
	const { data: familiesList } = useGetAllFamiliesByDistrictIdQuery({
		district: currentDistrict,
	});

	const [updateEngagement] = useUpdateEngagementMutation();
	const featchActionEdit = async (data: any) => {
		await updateEngagement({ id: data.get('_id')?.toString(), data }).unwrap();
		refetch();
	};
	const handleOpenModalEdit = useOpenModalForm({
		featchAction: featchActionEdit,
		isCreate: false,
		submitButtonName: t('engagements.forms.buttons.save'),
		toastText: 'Engagement record was successfully updated',
		fieldsListFromDistrict,
		providersList,
		familiesList,
	});

	const getCustomFieldValueById = (fieldId: string, engagement: EngagementDto) => {
		const engagementType = engagement?.customFieldsValues?.find(
			(el) => el.fieldId.toString() === engagement?.customFields?.find((el) => el.name === 'Type')?._id
		)?.value;

		const customFieldValue = engagement?.customFieldsValues?.find((el) => el.fieldId.toString() === fieldId.toString());

		const customField = engagement?.customFields?.find(
			(customField) =>
				(customField.engagementType === engagementType || // Include Both types
					customField.engagementType === ServiceTypeStatusEnum.Both) &&
				customField._id.toString() === fieldId.toString()
		);

		if (
			!customField ||
			!customFieldValue ||
			(customField.engagementType !== engagementType && customField.engagementType !== ServiceTypeStatusEnum.Both)
		) {
			return null;
		}

		if (customField?.dataType === FieldDataTypeEnum.Options) {
			return customFieldValue?.valuePlaceholder || null;
		}

		if (customField?.dataType === FieldDataTypeEnum.MultiSelect) {
			if (Array.isArray(customFieldValue?.value)) {
				const matchingTexts = customFieldValue?.value.map((val: string) => {
					const option = customField?.options?.find((opt) => opt._id === val);
					return option ? option.text : null;
				});

				return matchingTexts?.filter((text) => text !== null).join(', ') || null;
			} else {
				return null;
			}
		}

		return customFieldValue?.value || null;
	};

	const renderFileLinks = (customFieldValue: any) => {
		const value = customFieldValue?.value;

		if (value && typeof value === 'object') {
			const fileName = value.name || 'download';
			const fileUrl = value.url;

			return fileUrl ? (
				<a href={fileUrl} download={fileName} style={{ textDecoration: 'none' }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						<Typography>{fileName}</Typography>
						<Box>
							<DownloadIcon />
						</Box>
					</Box>
				</a>
			) : (
				fileName
			);
		}
		return null;
	};

	return (
		<Container
			maxWidth={false}
			disableGutters
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-start',
					width: '100%',
				}}
			>
				<Box>
					<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
						{t('engagements.details.details')}
					</Typography>
				</Box>

				<Box>
					<IconButton
						color="primary"
						onClick={() => {
							handleOpenModalEdit(engagement);
						}}
					>
						<EditIcon />
					</IconButton>
				</Box>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'flex-start',
					width: '100%',
					color: (theme) => theme.colors.darkBlack,
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						flex: '1 1 45%',
						gap: 2,
					}}
				>
					<Box>
						<Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.colors.grayBlue }}>
							{t('engagements.details.project')}
						</Typography>
						<Typography variant="body2" sx={{ fontSize: '14px', lineHeight: 1.5 }}>
							{engagement?.customId}
						</Typography>
					</Box>
					<Box>
						<Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.colors.grayBlue }}>
							{t('engagements.details.school')}
						</Typography>
						<Typography variant="body2" sx={{ fontSize: '14px', lineHeight: 1.5 }}>
							{engagement?.customFields?.find((el) => el.name === 'School')?._id
								? engagement?.customFieldsValues?.find(
										(el) => el.fieldId.toString() === engagement?.customFields?.find((el) => el.name === 'School')?._id
								  )?.valuePlaceholder
								: 'No School Information'}
						</Typography>
					</Box>
					<Box>
						<Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.colors.grayBlue }}>
							{t('engagements.details.duration')}
						</Typography>
						<Typography sx={{ fontSize: '14px', lineHeight: 1.5 }}>
							{(() => {
								const fieldDurationId = engagement?.customFields?.find((el) => el.name === 'Duration')?._id;
								const fieldDurationValue = engagement?.customFieldsValues?.find(
									(el) => el.fieldId.toString() === fieldDurationId
								)?.value;
								const fieldTypeId = engagement?.customFields?.find((el) => el.name === 'Type')?._id;
								const fieldTypeValue = engagement?.customFieldsValues?.find(
									(el) => el.fieldId.toString() === fieldTypeId
								)?.value;

								if (fieldTypeValue === DefaultFieldTypeStatusEnum.Project && fieldDurationValue?.startDate) {
									return `${format(new Date(fieldDurationValue.startDate), 'MM.dd.yyyy')}`;
								} else if (fieldDurationValue?.startDate && fieldDurationValue?.endDate) {
									return `${format(new Date(fieldDurationValue.startDate), 'MM.dd.yyyy')} - ${format(
										new Date(fieldDurationValue.endDate),
										'MM.dd.yyyy'
									)}`;
								} else {
									return 'No Duration Information';
								}
							})()}
						</Typography>
					</Box>
					<Box>
						<Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.colors.grayBlue }}>
							{t('engagements.details.email-status')}
						</Typography>
						<Typography sx={{ fontSize: '14px', lineHeight: 1.5 }}>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								{(() => {
									const fieldEmailStatusId = engagement?.customFields?.find((el) => el.name === 'Email status')?._id;
									const fieldEmailStatusValue = engagement?.customFieldsValues?.find(
										(el) => el.fieldId.toString() === fieldEmailStatusId
									)?.value as EmailStatusEnum;

									if (fieldEmailStatusValue) {
										return (
											<>
												<Box
													sx={{
														height: 10,
														width: 10,
														marginRight: 1,
														borderRadius: '50%',
														backgroundColor: EmailStatusColorEnum[fieldEmailStatusValue],
														flexShrink: 0,
													}}
												/>
												<Typography variant="body2" sx={{ fontSize: '14px', lineHeight: 1.5 }}>
													{fieldEmailStatusValue}
												</Typography>
											</>
										);
									} else {
										return <Typography variant="body2">No Email Status</Typography>;
									}
								})()}
							</Box>
						</Typography>
					</Box>
				</Box>

				<Box
					sx={{
						display: 'flex',
						height: '100%',
						flexDirection: 'column',
						flex: '1 1 45%',
						gap: 2,
					}}
				>
					<Box>
						<Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.colors.grayBlue }}>
							{t('engagements.details.type')}
						</Typography>
						<Typography sx={{ fontSize: '14px', lineHeight: 1.5 }}>
							{engagement?.customFields?.find((el) => el.name === 'Type')?._id
								? engagement?.customFieldsValues?.find(
										(el) => el.fieldId.toString() === engagement?.customFields?.find((el) => el.name === 'Type')?._id
								  )?.value
								: 'No Type Information'}
						</Typography>
					</Box>
					<Box>
						<Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.colors.grayBlue }}>
							{t('engagements.details.program')}
						</Typography>
						<Typography sx={{ fontSize: '14px', lineHeight: 1.5 }}>
							{engagement?.customFields?.find((el) => el.name === 'Program')?._id
								? engagement?.customFieldsValues?.find(
										(el) => el.fieldId.toString() === engagement?.customFields?.find((el) => el.name === 'Program')?._id
								  )?.valuePlaceholder
								: 'No Program Information'}
						</Typography>
					</Box>
					<Box>
						<Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.colors.grayBlue }}>
							{t('engagements.details.status')}
						</Typography>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Box
								sx={{
									height: 10,
									width: 10,
									marginRight: 1,
									borderRadius: '12px',
									backgroundColor:
										EngagementStatusColorEnum[engagement?.status as keyof typeof EngagementStatusColorEnum] ||
										'defaultColor',
									flexShrink: 0,
								}}
							></Box>
							<Typography variant="body2" sx={{ fontSize: '14px', lineHeight: 1.5 }}>
								{engagement?.status}
							</Typography>
						</Box>
					</Box>
					<Box>
						<Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.colors.grayBlue }}>
							{t('engagements.details.sms-status')}
						</Typography>

						<Typography variant="body2" sx={{ fontSize: '14px', lineHeight: 1.5 }}>
							Not sent
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
				<Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.colors.grayBlue }}>
					{t('engagements.details.service')}
				</Typography>
				<Typography sx={{ fontSize: '14px', lineHeight: 1.5 }}>
					{engagement?.customFields?.find((el) => el.name === 'Service')?._id
						? engagement?.customFieldsValues?.find(
								(el) => el.fieldId.toString() === engagement?.customFields?.find((el) => el.name === 'Service')?._id
						  )?.valuePlaceholder
						: 'No Program Service'}
				</Typography>
			</Box>
			<Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2, rowGap: 2 }}>
				{engagement?.customFields
					?.filter((customField) => !customField.defaultFieldId)
					?.filter((customField) => customField.dataType !== FieldDataTypeEnum.File)
					?.map((customField) => {
						const value = getCustomFieldValueById(customField._id, engagement);
						if (!value) return null;

						return (
							<Box
								key={customField._id}
								sx={{
									flex: '1 1 45%',
									width: '100%',
									gap: 2,
								}}
							>
								<Typography
									variant="body2"
									sx={{
										fontSize: '12px',
										color: (theme) => theme.colors.grayBlue,
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}
								>
									{customField?.name}
								</Typography>
								<Typography
									sx={{
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										fontSize: '14px',
										lineHeight: 1.5,
									}}
								>
									{value}
								</Typography>
							</Box>
						);
					})}
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mt: 2 }}>
				<Box
					sx={{
						backgroundColor: '#F2F3F6',
						width: '100%',
						p: 2,
						borderRadius: '12px',
					}}
				>
					<Typography
						variant="body2"
						sx={{
							fontSize: '12px',
							color: (theme) => theme.colors.grayBlue,
						}}
					>
						{t('engagements.details.description')}
					</Typography>
					<Typography>
						{engagement?.customFields?.find((el) => el.name === 'Description')?._id
							? engagement?.customFieldsValues?.find(
									(el) =>
										el.fieldId.toString() === engagement?.customFields?.find((el) => el.name === 'Description')?._id
							  )?.value
							: 'No Program Information'}
					</Typography>
				</Box>

				{engagement?.customFields
					?.filter((customField) => customField.dataType === FieldDataTypeEnum.File)
					?.map((customField) => {
						const customFieldValue = engagement?.customFieldsValues?.find(
							(el) => el.fieldId.toString() === customField._id.toString()
						);

						if (!customFieldValue || !customFieldValue.value) return null;

						return (
							<Box
								key={customField._id}
								sx={{
									display: 'flex',
									mt: 2,
									p: 2,
									width: '100%',
									borderRadius: '12px',
									border: '1px solid',
									borderColor: 'rgba(36, 57, 106, 0.16)',
								}}
							>
								<Box
									key={customField._id}
									sx={{
										width: '100%',
									}}
								>
									<Box>{renderFileLinks(customFieldValue)}</Box>
								</Box>
							</Box>
						);
					})}
			</Box>
		</Container>
	);
};
