import { IsEnum, IsNotEmpty, IsOptional, Length } from 'class-validator';
import { SurveyQuestionTypeEnum } from '../../enums';
import { FieldOptionInterface } from '../../interfaces';

export class CreateSurveyQuestionDto {
	@IsNotEmpty()
	@Length(1)
	text: String;
	@IsNotEmpty()
	@IsEnum(SurveyQuestionTypeEnum)
	type: SurveyQuestionTypeEnum;
	@IsNotEmpty()
	isRequired: Boolean;
	@IsOptional()
	options?: Array<FieldOptionInterface>;
}
