import { useMemo } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { LanguageEnum, ProviderDto, WorkStatusEnum, ServiceTypeStatusEnum } from '@families-link/shared';

import { Avatar, Typography, Box } from '@mui/material';
import { ProvidersDistrictTableHandlers } from '../components/provider-district-table/provider-district-table.component';
import { LanguageOptions, getObjectLanguage } from '../../_core/components/languages-component';
import { InHouse, Outsource, Verified } from '../../_core/constants/icons.constants';
import { ProvidersDistrictTableActions } from '../components/provider-district-table/provider-district-table-actions.component';

const helper = createColumnHelper<ProviderDto & { createdAt: string; updatedAt: string }>();

export function useProvidersDistrictTableColumns(
	handlers: ProvidersDistrictTableHandlers
): ColumnDef<ProviderDto, string>[] {
	const { onView, onDelete, onEdit } = handlers;

	const [t] = useTranslation();

	const result = useMemo(
		() => [
			helper.accessor('fullName', {
				header: t('providers.full-name'),
				meta: {
					flex: 1.5,
				},
				enableSorting: true,
				cell: ({ getValue, row }) => {
					const imageUrl = row.original.imageUrl;
					const fullName = getValue();

					return (
						<Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
							<Avatar
								src={imageUrl}
								alt={fullName}
								style={{ width: 24, height: 24, marginRight: 18, borderRadius: '6px' }}
							/>
							<Typography
								sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
							>
								{fullName}
							</Typography>
						</Box>
					);
				},
			}) as ColumnDef<ProviderDto, string>,
			helper.accessor('targetLanguage', {
				header: t('providers.target-lang'),
				meta: {
					flex: 1,
				},
				enableSorting: true,
				cell: (info) => {
					const languages = info.getValue() as string[];
					const options = languages.map(getObjectLanguage);

					return options.map((option) => (option && option.name ? option.name : '')).join(', ');
				},
			}) as ColumnDef<ProviderDto, string>,
			helper.accessor('verificationType', {
				header: t('providers.status'),
				meta: {
					flex: 0.8,
				},
				enableSorting: true,
				cell: (info) =>
					info.getValue() ? (
						<>
							{t('providers.verified')} <Verified style={{ marginLeft: '18px' }} />
						</>
					) : (
						t('providers.no-verified')
					),
			}) as ColumnDef<ProviderDto, string>,
			helper.accessor('workStatus', {
				header: t('providers.type'),
				meta: {
					flex: 0.8,
				},
				enableSorting: true,
				cell: (info) => {
					const value = info.getValue();
					switch (value) {
						case WorkStatusEnum.InHouse:
							return (
								<>
									<InHouse style={{ marginRight: '18px' }} />
									{t('providers.work-in-house')}
								</>
							);
						case WorkStatusEnum.Outsource:
							return (
								<>
									<Outsource style={{ marginRight: '18px' }} />
									{t('providers.work-outsource')}
								</>
							);
						default:
							return null;
					}
				},
			}) as ColumnDef<ProviderDto, string>,
			helper.accessor('serviceType', {
				header: t('providers.service-type'),
				meta: {
					flex: 1.2,
				},
				enableSorting: true,
				cell: (info) => {
					const value = info.getValue();
					switch (value) {
						case ServiceTypeStatusEnum.Appointment:
							return (
								<Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
									{t('providers.appointment')}
								</Typography>
							);
						case ServiceTypeStatusEnum.Project:
							return (
								<Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
									{t('providers.project')}
								</Typography>
							);
						case ServiceTypeStatusEnum.Both:
							return (
								<Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
									{t('providers.appointment')}, {t('providers.project')}
								</Typography>
							);
						default:
							return null;
					}
				},
			}) as ColumnDef<ProviderDto, string>,
			helper.accessor('totalEngagements', {
				header: 'Total Engagements',
				meta: {
					flex: 1,
				},
				enableSorting: true,
				cell: ({ getValue }) => {
					const totalEngagement = getValue();
					return <Typography>{totalEngagement}</Typography>;
				},
			}) as ColumnDef<ProviderDto>,
			helper.display({
				id: 'actions',
				cell: (props) => (
					<ProvidersDistrictTableActions
						provider={props.row.original}
						onView={onView}
						onDelete={onDelete}
						onEdit={onEdit}
					/>
				),
			}) as ColumnDef<ProviderDto>,
		],
		[t, onView, onDelete, onEdit]
	);

	return result;
}
