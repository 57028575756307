import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { AppLogo } from '../../constants/icons.constants';

interface SurveyHeaderProps {
	title: string;
}

const SurveyHeader: React.FC<{ title: string }> = ({ title }) => (
	<Box
		sx={{
			display: 'flex',
			flexWrap: 'nowrap',
		}}
	>
		<Box sx={{ flex: 1 }}>
			<Paper
				sx={{
					height: 56,
					px: 2,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
				elevation={0}
			>
				<Box>
					<Typography variant="h5">{title}</Typography>
				</Box>
				<Box component={AppLogo} sx={{ width: 180, height: 40 }} />
			</Paper>
		</Box>
	</Box>
);

export default SurveyHeader;
