import { FC, useMemo } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useMatch, useParams, useSearchParams } from 'react-router-dom';
import {
	useGetSurveyTemplateByIdQuery,
	useGetSurveyAnswersQuery,
	useWriteSurveyAnswersMutation,
} from '../../../../store/api/survey.api';
import { SurveyQuestionRender } from '../../components/question/question.component';
import SurveyHeader from '../../../_core/components/header-survey/header-survey.component';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import jwtDecode from 'jwt-decode';
import { JwtSurveyPayload } from '../../interfaces';
import { DistrictRoutes } from '../../../district/constants';
import { SurveyQuestionInterface } from '@families-link/shared';

export const SurveyViewPage: FC = () => {
	const [t] = useTranslation();
	const [searchParams] = useSearchParams();
	const { surveyTemplateId } = useParams();
	console.log('before token');
	const match = useMatch({
		path: DistrictRoutes.Survey,
		end: false,
	});

	const { token, tokenPayload } = useMemo(() => {
		const token = searchParams.get('token') ?? '';
		const tokenPayload = token ? jwtDecode<JwtSurveyPayload>(token) : undefined;

		return { token, tokenPayload };
	}, [searchParams]);

	console.log('after token');

	const { data: surveyTemplate } = useGetSurveyTemplateByIdQuery(surveyTemplateId ?? '');
	const { data: surveyAnswers } = useGetSurveyAnswersQuery({
		surveyId: tokenPayload?.sul ?? '',
		familyId: tokenPayload?.sub ?? '',
	});
	const [writeSurveyAnswers] = useWriteSurveyAnswersMutation();

	const defaultValues = {
		familyId: tokenPayload?.sub ?? '',
		surveyId: tokenPayload?.sul ?? '',
		fullName: '',
		...surveyTemplate?.questions?.reduce((result: any, el: SurveyQuestionInterface) => {
			result[`field_${el._id}`] = '';
			return result;
		}, {}),
	};

	const methods = useForm({
		defaultValues,
		mode: 'onSubmit',
	});

	const {
		register,
		handleSubmit,
		formState: { isDirty, isLoading, isSubmitting, isSubmitted, isValid },
	} = methods;

	const disableSubmit = !isValid && (isDirty || isSubmitted);
	const loadingSubmit = isLoading || isSubmitting;

	const onSubmit = (values: any) => {
		const transformValues = Object.keys(values).reduce(
			(submitObject: any, key: string) => {
				if (key.startsWith('field_')) {
					const fieldId = key.replace('field_', '');
					submitObject['answers'].push({
						fieldId,
						value: values[key],
					});
				} else {
					submitObject[key] = values[key];
				}
				return submitObject;
			},
			{ answers: [] }
		);
		writeSurveyAnswers(transformValues);
	};

	if (match && !token) {
		return null;
	}

	return (
		<Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', minHeight: 0, height: '100%' }}>
			<Box sx={{ width: '100%', height: '100%' }}>
				<Box component="header" sx={{ mb: 1 }}>
					<SurveyHeader title={surveyTemplate?.title ?? 'Survey'} />
				</Box>
				{surveyAnswers ? (
					<Paper elevation={0} sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
						<Typography fontSize={14} sx={{ fontWeight: '500' }}>
							{'Your response has been recorded'}
						</Typography>
					</Paper>
				) : (
					<FormProvider {...methods}>
						<Box
							component="form"
							onSubmit={handleSubmit(onSubmit)}
							sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
						>
							<Paper elevation={0} sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
								<Typography fontSize={14} sx={{ fontWeight: '500', mb: '10px' }}>
									{t('survey-templates.forms.placeholders.survey-name')}
									<span style={{ color: 'red', marginLeft: '4px', fontWeight: '300' }}>*</span>
								</Typography>
								<CoreTextField
									requiredMark
									fullWidth
									placeholder={t('survey-templates.forms.placeholders.survey-name') ?? ''}
									{...register('fullName')}
								/>
							</Paper>
							{surveyTemplate?.questions?.map((question, index) => (
								<Paper
									key={question._id.toString()}
									elevation={0}
									sx={{ p: 3, display: 'flex', flexDirection: 'column' }}
								>
									<Typography fontSize={14} sx={{ fontWeight: '500', mb: '10px' }}>
										{index + 1}. {question.text}{' '}
										{question.isRequired && (
											<span style={{ color: 'red', marginLeft: '4px', fontWeight: '300' }}>*</span>
										)}
									</Typography>
									<SurveyQuestionRender question={question} />
								</Paper>
							))}
							{match ? (
								<Box>
									<CoreButton type="submit" contraMode disabled={disableSubmit} loading={loadingSubmit}>
										{'Submit'}
									</CoreButton>
								</Box>
							) : null}
						</Box>
					</FormProvider>
				)}
			</Box>
		</Box>
	);
};
