import { RouteObject } from 'react-router-dom';
import { ProfilePage } from './pages/profile/profile.page';
import { ProfileSecurityPage } from './pages/security/security.page';

import { ProfileRoutes } from './constants';
import { ProvidersPage } from '../providers/pages/providers.page';
import { DistrictListPage } from '../district/pages/district-list/district-list.page';
import { FamiliesListAdminLevelPage } from '../family/pages/family-list/family-list-admin-level.page';
import { DashboardPage } from '../dashboard/pages/dashboard.page';
import { DistributionPage } from '../_core/pages/distribution/distribution.page';
import { EngagementsListAdminLevelPage } from '../engagement/pages/engagements-list/engagement-list-admin-level.page';

export const ProfileSuperAdminModuleRouter: RouteObject[] = [
	{ path: ProfileRoutes.Root, element: <DashboardPage />, handle: { title: 'nav.dashboard', needsPaper: false } },
	{ path: ProfileRoutes.Districts, element: <DistrictListPage />, handle: { title: 'nav.districts' } },
	{ path: ProfileRoutes.Engagements, element: <EngagementsListAdminLevelPage />, handle: { title: 'nav.engagements' } },
	{ path: ProfileRoutes.Providers, element: <ProvidersPage />, handle: { title: 'nav.providers' } },
	{ path: ProfileRoutes.Families, element: <FamiliesListAdminLevelPage />, handle: { title: 'nav.families' } },
];

export const ProfileModuleRouter: RouteObject[] = [
	{ path: ProfileRoutes.Distribute, element: <DistributionPage /> },
	{ path: ProfileRoutes.Profile, element: <ProfilePage />, handle: { title: 'nav.profile', to: ProfileRoutes.Root } },
	{
		path: ProfileRoutes.SecuritySettings,
		element: <ProfileSecurityPage />,
		handle: { title: 'nav.profile', to: ProfileRoutes.Root },
	},
];
