import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const SendSmsIcon: FC<SvgIconProps> = (props) => (
	<SvgIcon {...props}>
		<svg fill="none" strokeWidth={1.5} stroke="currentColor">
			<g transform="translate(3, 3)">
				<path d="M4.88892 6.13335H12.1111M4.88892 9.04004H9.22225" />
				<path d="M2 15.0642V3.95334C2 3.56789 2.15218 3.19823 2.42307 2.92567C2.69395 2.65312 3.06135 2.5 3.44444 2.5H13.5556C13.9386 2.5 14.306 2.65312 14.5769 2.92567C14.8478 3.19823 15 3.56789 15 3.95334V11.2201C15 11.6055 14.8478 11.9752 14.5769 12.2477C14.306 12.5203 13.9386 12.6734 13.5556 12.6734H5.58294C5.36645 12.6734 5.15272 12.7224 4.95758 12.8168C4.76244 12.9111 4.59087 13.0484 4.45556 13.2184L2.77206 15.3359C2.71603 15.4066 2.63957 15.458 2.55325 15.483C2.46693 15.508 2.37502 15.5054 2.29023 15.4755C2.20544 15.4457 2.13197 15.3901 2.07998 15.3164C2.02798 15.2427 2.00003 15.1545 2 15.0642Z" />
			</g>
		</svg>
	</SvgIcon>
);
