import { useCallback } from 'react';
import { useSignOutMutation } from '../../../store/api/auth.api';
import { useCurrentRefreshTokenSelector } from '../store/session.slice';
import { useClearStore } from '../../_core/hooks';

export const useSignOut = () => {
	const refreshToken = useCurrentRefreshTokenSelector();

	const [signOut] = useSignOutMutation();
	const clearStore = useClearStore();

	return useCallback(async () => {
		if (!refreshToken?.token) return;

		await signOut(refreshToken?.token);
		clearStore();
	}, [refreshToken?.token, signOut]);
};
