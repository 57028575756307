import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DeleteIcon: FC<SvgIconProps> = (props) => (
	<SvgIcon {...props}>
		<svg fill="none" strokeWidth={1.5} stroke="currentColor">
			<g transform="translate(3, 3) scale(1.1)">
				<path d="M3.79999 5.5H12.2V13.3C12.2 13.6183 12.0735 13.9235 11.8484 14.1485C11.6234 14.3735 11.3182 14.5 11 14.5H4.99999C4.68173 14.5 4.37645 14.3735 4.1514 14.1485C3.92636 13.9235 3.79999 13.6183 3.79999 13.3V5.5Z" />
				<path d="M2 5.5H4.18994C4.42856 5.49951 4.66165 5.42793 4.85937 5.29433C5.05709 5.16074 5.21051 4.97121 5.3 4.75L5.9 3.25601C5.98854 3.03368 6.14151 2.84287 6.3393 2.70815C6.5371 2.57344 6.7706 2.50097 7.00991 2.5H8.98994C9.22925 2.50097 9.46275 2.57344 9.66054 2.70815C9.85834 2.84287 10.0114 3.03368 10.1 3.25601L10.7 4.75C10.7899 4.97215 10.9441 5.16235 11.143 5.29602C11.3419 5.42969 11.5763 5.50071 11.8159 5.5H14" />
			</g>
		</svg>
	</SvgIcon>
);
