export const ACCESS_TOKEN_STORAGE_KEY = 'access_token';
export const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';

export const AUTH_KEYS_LIST = [ACCESS_TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY];

export const REFRESH_LOCK_KEY = 'refresh_lock';

export const CURRENT_DISTRICT_ID_LOCAL_STORAGE_KEY = 'current_district_id_local_storage_key';
export const CURRENT_FAMILY_ID_LOCAL_STORAGE_KEY = 'current_family_id_local_storage_key';
export const CURRENT_PROVIDER_ID_LOCAL_STORAGE_KEY = 'current_provider_id_local_storage_key';
export const CURRENT_ENGAGEMENT_ID_LOCAL_STORAGE_KEY = 'current_engagement_id_local_storage_key';
export const CURRENT_SURVEY_TEMPLATE_ID_LOCALE_STORAGE_KEY = 'current_survey_template_id_local_storage_key';
