export const getFileType = (mimeType: string) => {
	const imageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg'];
	const videoTypes = ['video/mp4', 'video/mkv', 'video/webm', 'video/quicktime'];
	const documentTypes = [
		'text/plain',
		'application/pdf',
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'text/csv',
		'application/vnd.ms-powerpoint',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	];

	if (imageTypes.includes(mimeType)) {
		return 'image';
	} else if (videoTypes.includes(mimeType)) {
		return 'video';
	} else if (documentTypes.includes(mimeType)) {
		return 'document';
	} else {
		return 'unknown';
	}
};
