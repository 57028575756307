import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const EditIcon: FC<SvgIconProps> = (props) => (
	<SvgIcon {...props}>
		<svg fill="currentColor" strokeWidth={0} stroke="currentColor">
			<g transform="translate(3, 3)">
				<path d="M10.88 1.5C10.2644 1.5 9.68509 1.75754 9.26554 2.19773L9.26322 2.20017L1.2738 10.6395C1.09798 10.8252 1 11.0712 1 11.3269V14.5C1 15.0523 1.44772 15.5 2 15.5H4.99368C5.0491 15.5 5.10403 15.4954 5.15796 15.4864V15.4933H14.0001V13.4933H7.32499L13.715 6.7489C13.9221 6.53043 14.0847 6.27323 14.1945 5.99363C14.3043 5.71413 14.3599 5.41647 14.3599 5.11744C14.3599 4.81836 14.3043 4.52079 14.1945 4.24134C14.0847 3.96175 13.9225 3.70496 13.7154 3.4865L12.4965 2.1999L12.4944 2.19773C12.0749 1.75764 11.4958 1.5 10.88 1.5ZM10.7133 3.57759L10.714 3.57682C10.7676 3.52096 10.8285 3.5 10.88 3.5C10.9315 3.5 10.9922 3.52088 11.0459 3.57688L12.2635 4.862C12.2911 4.89109 12.3157 4.92856 12.3329 4.97248C12.3503 5.01673 12.3599 5.06612 12.3599 5.11744C12.3599 5.16883 12.3503 5.21829 12.3329 5.26249C12.3157 5.30642 12.2915 5.34346 12.2639 5.37254L4.56357 13.5H3V11.7252L10.7133 3.57759Z" />
			</g>
		</svg>
	</SvgIcon>
);
