import { SxProps, Theme } from '@mui/material';

export const titleStyles: SxProps<Theme> = [
	{
		mt: 3,
		mb: 4,
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: '24px',
		lineHeight: '120%',
	},
	({ breakpoints }) => ({
		[breakpoints.down('sm')]: {
			my: 3,
			fontSize: '20px',
		},
	}),
];

export const formElementStyles: SxProps<Theme> = [
	{
		display: 'flex',
		justifyContent: 'space-between',
		'& input::placeholder': {
			fontSize: '12px',
		},
	},
	({ breakpoints }) => ({
		[breakpoints.down('sm')]: {
			my: 1,
		},
	}),
];

export const linkStyles: SxProps<Theme> = [
	{
		display: 'block',
		color: (theme) => theme.colors.gray,
		mb: 3,
		textDecorationColor: 'inherit',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	({ breakpoints }) => ({
		[breakpoints.down('sm')]: {
			mb: 1,
		},
	}),
];

export const errorLabelTextStyles: (hasError: boolean) => SxProps<Theme> = (hasError) => [
	{
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		columnGap: '0.4rem',
		mt: 2,
		color: (theme) => theme.colors.red,
	},
	({ breakpoints }) => ({
		[breakpoints.down('sm')]: {
			mt: 3,
		},
	}),
];
