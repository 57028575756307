import { FC } from 'react';
import { Container } from '@mui/material';
import { useInitiateState } from '../../../_core/hooks';
import { DashboardPage } from '../../../dashboard/pages/dashboard.page';

export const DistrictDashboardPage: FC = () => {
	useInitiateState('district');

	return <DashboardPage />;
};
