import React, { useState, useEffect, useCallback } from 'react';

export const usePaginatedData = <T>(useGetQuery: any, params: any) => {
	const [page, setPage] = useState(1);
	const [itemsList, setItemsList] = useState<T[]>([]);

	const handleResetPage = useCallback(() => {
		setPage(1);
	}, []);

	const { data, refetch, isFetching } = useGetQuery({
		page,
		...params,
	});

	useEffect(() => {
		refetch();
	}, [page, refetch]);

	useEffect(() => {
		if (data) {
			if (data.meta?.page === 1) {
				setItemsList(data.data);
			} else {
				setItemsList((prevItems) => [...prevItems, ...data.data]);
			}
		}
	}, [data]);

	const fetchMore = useCallback(() => {
		setPage(page + 1);
	}, [page]);

	return {
		itemsList,
		fetchMore,
		page,
		handleResetPage,
		refetch,
		isFetching,
		meta: data?.meta,
	};
};
