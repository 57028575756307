import { FC, useCallback, useRef, useState, useEffect } from 'react';
import { CoreInput } from '../../_core/components/_ui/core-input';
import {
	useDeleteProviderMutation,
	useGetAllProvidersQuery,
	useUpdateProviderMutation,
} from '../../../store/api/providers.api';
import { ProvidersTable } from '../components/providers-table/providers-table.component';
import { Box, Chip, Container, InputAdornment, MenuItem, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { LanguageEnum, ProviderDto, ProviderInfoDto } from '@families-link/shared';
import { DeleteProviderModal } from '../components/delete-provider-modal/delete-provider-modal.component';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { EditProviderForm } from '../components/edit-provider-form/edit-provider-form.component';
import { useClearStore, useLanguageFilter, useSortingParams, useSource } from '../../_core/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { setLocalStorageProviderId } from '../../_core/utils';
import { DistrictRoutes } from '../../district/constants';
import { CoreAutoComplete } from '../../_core/components/_ui/core-autocomplete';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { getObjectLanguage } from '../../_core/components/languages-component';

export const ProvidersPage: FC = () => {
	const [t] = useTranslation();

	const { enqueueSnackbar } = useSnackbar();

	const { register, source } = useSource();
	const { sortingArray, sortingParams, handleSortingChange } = useSortingParams();

	const { register: registerLang, getValues, control, languages } = useLanguageFilter();
	const location = useLocation();

	const clearStore = useClearStore();

	useEffect(() => {
		clearStore();
	}, []);

	const {
		data: providers,
		error,
		isLoading,
		refetch,
	} = useGetAllProvidersQuery({
		languages: languages.join(','),
		sortBy: sortingParams.orderField,
		sortOrder: sortingParams.order,
		searchQuery: source,
	});

	useEffect(() => {
		refetch();
	}, [location]);

	const [updateProvider] = useUpdateProviderMutation();
	const [deleteProvider] = useDeleteProviderMutation();

	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const [selectedProvider, setSelectedProvider] = useState<ProviderDto | null>(null);

	const resolverUpdate = classValidatorResolver(ProviderInfoDto);

	const handleOnEdit = (provider: ProviderDto) => {
		setSelectedProvider(provider);
		setEditDialogOpen(true);
	};

	const handleOnEditProvider = useCallback(
		async (updatedProvider: FormData) => {
			try {
				const providerId = updatedProvider?.get('_id')?.toString();
				if (providerId) {
					await updateProvider({ id: providerId, updateData: updatedProvider }).unwrap();
					setEditDialogOpen(false);
					setSelectedProvider(null);
					enqueueSnackbar('Provider updated successfully!', { variant: 'success' });
					refetch();
				} else {
					console.error('Provider ID is null or undefined');
				}
			} catch (error) {
				console.error('Failed to update provider:', error);
			}
		},
		[updateProvider, refetch, enqueueSnackbar, setEditDialogOpen, setSelectedProvider]
	);

	const handleOnDelete = useCallback(
		async (providerId: string) => {
			try {
				await deleteProvider(providerId).unwrap();
				enqueueSnackbar('Provider deleted successfully!', { variant: 'success' });
				refetch();
			} catch (error) {
				console.log(error);
			}
		},
		[deleteProvider, refetch, enqueueSnackbar]
	);

	const openDeleteModal = (provider: ProviderDto) => {
		setSelectedProvider(provider);
		setDeleteDialogOpen(true);
	};

	const navigate = useNavigate();

	const handleOnView = (provider: ProviderDto) => {
		setLocalStorageProviderId(provider._id);
		navigate(DistrictRoutes.Providers.Details.replace(':providerId', provider._id));
	};

	const scrollRef = useRef<HTMLDivElement>(null);

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-start',
					width: '100%',
				}}
			>
				<Box>
					<CoreInput
						{...register('source')}
						placeholder={t('common.search-by-name')}
						endAdornment={
							<InputAdornment position="end">
								<SearchIcon />
							</InputAdornment>
						}
						sx={{ marginRight: '8px' }}
					/>
					<CoreAutoComplete
						multiple={true}
						{...registerLang('languages')}
						control={control}
						controlSx={{ minWidth: '220px' }}
						IconComponent={<FilterAltOutlinedIcon />}
						options={Object.values(LanguageEnum)}
						getOptionLabel={(option) => {
							const language = getObjectLanguage(option);
							return language ? language.name : '';
						}}
						selectOnFocus={true}
						isOptionEqualToValue={(option, value) => option === value}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder={!getValues('languages')?.length ? t('common.filter-lang') : ''}
								sx={{
									'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
										padding: '4.5px 4px 5.5px 5px',
										minWidth: 0,
									},
									'& .MuiInputBase-input': {
										height: '100%',
										padding: '0',
									},
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: (theme) => theme.colors.blue,
										},
									},
								}}
							/>
						)}
						renderOption={(props, option) => {
							const language = getObjectLanguage(option);

							return (
								<MenuItem {...props} key={language?.value}>
									{language?.label}
								</MenuItem>
							);
						}}
						renderTags={(value, getTagProps) => {
							const count = value.length - 1;
							return [
								value[0] && <Chip {...getTagProps({ index: 0 })} label={getObjectLanguage(value[0])?.name} />,
								count > 0 && <Chip {...getTagProps({ index: count })} label={`+${count} more`} />,
							];
						}}
					/>
				</Box>
			</Box>

			<Box sx={{ marginTop: 2, overflowY: 'auto' }} ref={scrollRef}>
				<ProvidersTable
					data={providers || []}
					loading={isLoading}
					sorting={sortingArray}
					onSortingChange={handleSortingChange}
					onDelete={openDeleteModal}
					onEdit={handleOnEdit}
					onView={handleOnView}
				/>
			</Box>

			{selectedProvider && (
				<EditProviderForm
					open={editDialogOpen}
					onClose={() => setEditDialogOpen(false)}
					submitAction={handleOnEditProvider}
					resolver={resolverUpdate}
					defaultValues={selectedProvider}
				/>
			)}

			{selectedProvider && (
				<DeleteProviderModal
					open={deleteDialogOpen}
					onClose={() => setDeleteDialogOpen(false)}
					providerId={selectedProvider._id}
					providerName={selectedProvider.fullName}
					onSubmit={handleOnDelete}
				/>
			)}
		</Container>
	);
};
