import React, { FC, useEffect } from 'react';
import { UsersTable } from '../../components/users-table';
import { Box, Container, InputAdornment } from '@mui/material';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import SearchIcon from '@mui/icons-material/Search';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { useTranslation } from 'react-i18next';
import { CreateUserDto, UserDto } from '@families-link/shared';
import { useSource, useSortingParams, usePaginatedData, useInitiateState } from '../../../_core/hooks';
import {
	useCreateUserMutation,
	useDeleteUserMutation,
	useGetUsersListQuery,
	useUpdateUserMutation,
} from '../../../../store/api/users.api';
import { useOpenModalForm } from '../../hooks/modal-forms/use-open-modal-form.hook';
import { useOpenConfirmModal } from '../../hooks/modal-forms/use-open-confirm-modal-form.hook';
import { useInviteRequestMutation, useRestorePasswordRequestMutation } from '../../../../store/api/auth.api';
import { getLocalStorageDistrictId } from '../../../_core/utils';
import AddIcon from '@mui/icons-material/Add';
import { DeleteIcon, InfoIcon } from '../../../_core/components/icons';

export const UsersListPage: FC = () => {
	useInitiateState('district');

	const [t] = useTranslation();

	const { register, source } = useSource();
	const { sortingArray, sortingParams, handleSortingChange } = useSortingParams();
	const take = 15;
	const { isFetching, meta, page, handleResetPage, itemsList, refetch, fetchMore } = usePaginatedData<UserDto>(
		useGetUsersListQuery,
		{ take, source, ...sortingParams, district: getLocalStorageDistrictId() }
	);
	useEffect(() => {
		handleResetPage();
	}, [sortingArray, source]);

	const handlePagination = () => {
		handleResetPage();
		if (page === 1) {
			refetch();
		}
	};

	const [inviteRequest, { reset: resetInvitation }] = useInviteRequestMutation();
	const featchActionSendInvitation = async (data: UserDto) => {
		await inviteRequest({ email: data.email }).unwrap();
	};
	useEffect(() => {
		resetInvitation();
	}, [resetInvitation]);

	const handleOpenInvitationConfirmModal = useOpenConfirmModal({
		featchAction: featchActionSendInvitation,
		handlePagination,
		title: t('users.forms.send-invitation-title'),
		description: t('users.forms.send-invitation-description'),
		icon: <InfoIcon sx={{ fontSize: 40 }} />,
		customBackgrondColorKey: 'grayLight',
		submitButtonName: t('users.forms.buttons.send'),
		toastText: 'Email was successfully sent',
	});

	const [createUser] = useCreateUserMutation();
	const featchActionCreate = async (data: CreateUserDto) => {
		await createUser({ ...data }).unwrap();
		await inviteRequest({ email: data.email }).unwrap();
	};
	const handleOpenModalCreate = useOpenModalForm({
		featchAction: featchActionCreate,
		handlePagination,
		isCreate: true,
		submitButtonName: t('users.forms.buttons.create'),
		toastText: 'User was successfully created',
	});

	const [updateUser] = useUpdateUserMutation();
	const featchActionEdit = async (data: UserDto) => {
		await updateUser({ id: data._id, data: { ...data } }).unwrap();
	};
	const handleOpenModalEdit = useOpenModalForm({
		featchAction: featchActionEdit,
		handlePagination,
		isCreate: false,
		submitButtonName: t('users.forms.buttons.save'),
		toastText: 'User was successfully updated',
	});

	const [deleteUser] = useDeleteUserMutation();
	const featchActionDelete = async (data: UserDto) => {
		await deleteUser({ id: data._id }).unwrap();
	};
	const handleOpenDeleteConfirmModal = useOpenConfirmModal({
		featchAction: featchActionDelete,
		handlePagination,
		title: t('users.forms.delete-title'),
		description: t('users.forms.delete-description'),
		icon: <DeleteIcon sx={{ fontSize: 40 }} />,
		danger: true,
		submitButtonName: t('users.forms.buttons.delete'),
		toastText: 'User was successfully deleted',
	});

	const [restorePasswordRequest, { reset: resetRestorePass }] = useRestorePasswordRequestMutation();
	const featchActionResetPassword = async (data: UserDto) => {
		await restorePasswordRequest({ email: data.email }).unwrap();
	};
	useEffect(() => {
		resetRestorePass();
	}, [resetRestorePass]);
	const handleOpenResetPasswordConfirmModal = useOpenConfirmModal({
		featchAction: featchActionResetPassword,
		handlePagination,
		title: t('users.forms.reset-password-title'),
		description: t('users.forms.reset-password-description'),
		icon: <InfoIcon sx={{ fontSize: 40 }} color="primary" />,
		customBackgrondColorKey: 'grayLight',
		submitButtonName: t('users.forms.buttons.send'),
		toastText: 'Email was successfully sent',
	});

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
				<CoreTextField
					{...register('source')}
					placeholder={t('common.search-by-name')}
					endAdornment={
						<InputAdornment position="end">
							<SearchIcon />
						</InputAdornment>
					}
					sx={{ marginRight: '16px', width: '400px' }}
				/>
				<CoreButton
					sx={{
						display: 'flex',
						fontSize: '16px',
						padding: '12px 24px',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '8px',
						marginLeft: 'auto',
					}}
					onClick={() => {
						handleOpenModalCreate();
					}}
				>
					<AddIcon />
					{t('users.create-user')}
				</CoreButton>
			</Box>
			<UsersTable
				data={itemsList}
				loading={isFetching}
				hasMore={meta?.hasNextPage}
				onDelete={handleOpenDeleteConfirmModal}
				onEdit={handleOpenModalEdit}
				onMailSend={handleOpenInvitationConfirmModal}
				onResetPassword={handleOpenResetPasswordConfirmModal}
				sorting={sortingArray}
				onSortingChange={handleSortingChange}
				fetchMore={fetchMore}
			/>
		</Container>
	);
};
