export enum EmailStatusEnum {
	NotShared = 'Not shared',
	Delivered = 'Delivered',
}

export const EmailStatusArray = [EmailStatusEnum.NotShared, EmailStatusEnum.Delivered];

export const EmailStatusColorEnum = {
	[EmailStatusEnum.NotShared]: '#EEE764',
	[EmailStatusEnum.Delivered]: '#93E9B3',
};
