import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useForm } from 'react-hook-form';

export const useSource = () => {
	const [source, setSource] = useState('');
	const { register, watch } = useForm({
		defaultValues: { source: '' },
	});
	const searchTerm = watch('source');
	const debouncedUpdate = useCallback(
		debounce((term: string) => {
			setSource(term);
		}, 300),
		[]
	);

	useEffect(() => {
		debouncedUpdate(searchTerm);
	}, [searchTerm, debouncedUpdate]);

	return {
		register,
		source,
	};
};
