import { FC, useCallback, useState } from 'react';
import { useInitiateState } from '../../../_core/hooks';
import { getLocalStorageProviderId } from '../../../_core/utils';
import { useGetProviderByIdQuery, useUpdateProviderMutation } from '../../../../store/api/providers.api';
import { Avatar, Box, Container, Grid, IconButton, Typography } from '@mui/material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { getObjectLanguage, LanguageOptions } from '../../../_core/components/languages-component';
import { EditIcon, InHouse, Outsource, Verified } from '../../../_core/constants/icons.constants';
import {
	ProviderDto,
	ProviderInfoDto,
	UserRolesEnum,
	WorkStatusEnum,
	ServiceTypeStatusEnum,
} from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { EditProviderForm } from '../edit-provider-form/edit-provider-form.component';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useCurrentAccessTokenSelector } from '../../../auth';

interface ProvidersDetailsProps {
	providerId?: string;
}

export const ProvidersDetails: FC<ProvidersDetailsProps> = ({ providerId: propProviderId }) => {
	useInitiateState('provider');

	const providerId = propProviderId || getLocalStorageProviderId();

	const currentToken = useCurrentAccessTokenSelector();
	const isRequester = currentToken?.payload.sur === UserRolesEnum.Requester;
	const isAdmin = currentToken?.payload.sur === UserRolesEnum.Admin;

	const { data: provider, error, isLoading, refetch } = useGetProviderByIdQuery(providerId || '');
	const [t] = useTranslation();

	const { enqueueSnackbar } = useSnackbar();

	const languageCodes = provider?.targetLanguage;
	const optionsLang = Array.isArray(languageCodes) ? languageCodes.map(getObjectLanguage) : [];
	const options = optionsLang.map((option) => (option ? option.name : '')).join(', ');

	const [selectedProvider, setSelectedProvider] = useState<ProviderDto | null>(null);

	const [updateProvider] = useUpdateProviderMutation();
	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const resolverUpdate = classValidatorResolver(ProviderInfoDto);

	const handleOnEdit = (provider: ProviderDto) => {
		setSelectedProvider(provider);
		setEditDialogOpen(true);
	};

	const handleOnEditProvider = useCallback(
		async (updatedProvider: FormData) => {
			try {
				const providerId = updatedProvider?.get('_id')?.toString();
				if (providerId) {
					await updateProvider({ id: providerId, updateData: updatedProvider }).unwrap();

					setEditDialogOpen(false);
					setSelectedProvider(null);

					enqueueSnackbar('Provider updated successfully!', { variant: 'success' });
					refetch();
				} else {
					enqueueSnackbar('Provider is updated!', { variant: 'error' });
				}
			} catch (error) {
				enqueueSnackbar('Provider is updated!', { variant: 'error' });
			}
		},
		[updateProvider, refetch, enqueueSnackbar, setEditDialogOpen, setSelectedProvider]
	);

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-start',
					width: '100%',
					mt: 1,
				}}
			>
				<Box>
					<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
						{t('providers.details')}
					</Typography>
				</Box>

				<Box>
					<IconButton
						color="primary"
						disabled={isRequester || isAdmin}
						onClick={() => provider && handleOnEdit(provider)}
					>
						<EditIcon />
					</IconButton>
				</Box>

				{selectedProvider && (
					<EditProviderForm
						open={editDialogOpen}
						onClose={() => setEditDialogOpen(false)}
						submitAction={handleOnEditProvider}
						resolver={resolverUpdate}
						defaultValues={selectedProvider}
					/>
				)}
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%' }}>
				<Box
					sx={{
						flexGrow: 1,
						padding: 1,
						display: 'flex',
						flexDirection: 'row',
						minWidth: '280px',
						gap: 3,
					}}
				>
					<Avatar
						src={provider?.imageUrl}
						alt={provider?.fullName}
						style={{ width: 98, height: 98, borderRadius: '6px' }}
					/>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
						}}
					>
						<Box>
							<Typography variant="body2" sx={{ fontWeight: 'regular', color: (theme) => theme.colors.grayBlue }}>
								{t('providers.full-name')}
							</Typography>
							<Typography sx={{ fontWeight: 'bold' }}>{provider?.fullName}</Typography>
						</Box>
						<Box>
							<Typography variant="body2" sx={{ fontWeight: 'regular', color: (theme) => theme.colors.grayBlue }}>
								{t('providers.target-lang')}
							</Typography>
							<Box sx={{ fontWeight: 'bold' }}>{options}</Box>
						</Box>
					</Box>
				</Box>

				<Box
					sx={{
						flexGrow: 1,
						padding: 1,
						display: 'flex',
						height: '100%',
						minWidth: '120px',
					}}
				>
					<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
						<Box>
							<Typography variant="body2" sx={{ fontWeight: 'regular', color: (theme) => theme.colors.grayBlue }}>
								{t('providers.type')}
							</Typography>
							<Typography sx={{ fontWeight: 'bold' }}>
								{provider?.verificationType ? (
									<>
										{t('providers.verified')} <Verified style={{ marginLeft: '8px' }} />
									</>
								) : (
									t('providers.no-verified')
								)}
							</Typography>
						</Box>
						<Box>
							<Typography variant="body2" sx={{ fontWeight: 'regular', color: (theme) => theme.colors.grayBlue }}>
								{t('providers.status')}
							</Typography>
							<Typography sx={{ fontWeight: 'bold' }}>
								{provider?.workStatus === WorkStatusEnum.InHouse ? (
									<>
										<InHouse style={{ marginRight: '8px' }} />
										{t('providers.work-in-house')}
									</>
								) : (
									<>
										<Outsource style={{ marginRight: '8px' }} />
										{t('providers.work-outsource')}
									</>
								)}
							</Typography>
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						flexGrow: 1,
						padding: 1,
						display: 'flex',
						height: '100%',
						minWidth: '120px',
					}}
				>
					<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
						<Box>
							<Typography variant="body2" sx={{ fontWeight: 'regular', color: (theme) => theme.colors.grayBlue }}>
								{t('providers.service-type')}
							</Typography>
							<Typography sx={{ fontWeight: 'bold' }}>
								{provider?.serviceType === ServiceTypeStatusEnum.Appointment
									? t('providers.appointment')
									: provider?.serviceType === ServiceTypeStatusEnum.Project
									? t('providers.project')
									: provider?.serviceType === ServiceTypeStatusEnum.Both
									? `${t('providers.appointment')}, ${t('providers.project')}`
									: ''}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</Container>
	);
};
