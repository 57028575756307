import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { DefaultValues, useForm } from 'react-hook-form';

type LanguageFilterFormValues = {
	languages: [];
};

export const useLanguageFilter = () => {
	const [languages, setLanguages] = useState<string[]>([]);

	const defaultValues: DefaultValues<LanguageFilterFormValues> = {
		languages: [],
	};
	const { register, getValues, control, watch } = useForm({
		defaultValues,
	});
	const filter = watch('languages');
	const debouncedUpdate = useCallback(
		debounce((list?: string[]) => {
			setLanguages(list || []);
		}, 300),
		[]
	);

	useEffect(() => {
		debouncedUpdate(filter);
	}, [filter, debouncedUpdate]);

	return {
		register,
		getValues,
		control,
		languages,
	};
};
