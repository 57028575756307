import { FC, useRef, useCallback, useEffect } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	useCreateCustomFieldMutation,
	useDeleteCustomFieldMutation,
	useGetAllFieldsQuery,
	useUpdateCustomFieldMutation,
} from '../../../store/api/customRequirements.api';
import { useParams, useLocation } from 'react-router-dom';
import { CustomRequirementsTable } from '../components/custom-requirements-table';
import { skipToken } from '@reduxjs/toolkit/query';
import { CoreButton } from '../../_core/components/_ui/core-button';
import AddIcon from '@mui/icons-material/Add';
import {
	AddCustomFieldForm,
	AddCustomFieldFormProps,
} from '../components/create-cutom-field/create-custom-field-form.component';
import { CustomFieldDto, CustomFieldInfoDto } from '@families-link/shared';
import { useSnackbar } from 'notistack';
import { useInitiateState, useMuiModal, useSortingParams } from '../../_core/hooks';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { ConfirmationModal, ConfirmationModalProps } from '../../_core/components/confirmation-modal';
import { DeleteIcon } from '../../_core/components/icons';
import { buttonModalWindowStyles } from '../../_core/styles';

export const CustomRequirementsListPage: FC = () => {
	useInitiateState('district');
	const { districtId } = useParams();
	const [t] = useTranslation();

	const { enqueueSnackbar } = useSnackbar();
	const { sortingArray, sortingParams, handleSortingChange } = useSortingParams();

	const {
		data: fields,
		error,
		isFetching: isFetchingCustomFields,
		refetch,
	} = useGetAllFieldsQuery(
		districtId ? { districtId, sortBy: sortingParams.orderField, sortOrder: sortingParams.order } : skipToken
	);

	const location = useLocation();

	useEffect(() => {
		refetch();
	}, [location]);

	const [createCustomField] = useCreateCustomFieldMutation();

	const {
		openModal: openCreateModal,
		closeModal: closeCreateModal,
		updateCurrentModal: updateCreateModal,
	} = useMuiModal<AddCustomFieldFormProps>(AddCustomFieldForm);

	const defaultCreateValues = {
		name: '',
		engagementType: '',
		isRequired: undefined,
		dataType: '',
		options: [] as { text: string }[],
	};
	const handleAddCustomField = useCallback(
		async (newCustomField: CustomFieldInfoDto) => {
			if (!districtId) {
				return;
			}
			const updatedCustomField = { ...newCustomField, districtId };
			await createCustomField(updatedCustomField).unwrap();
			enqueueSnackbar(t('custom-fields.added'), { variant: 'success' });
			closeCreateModal();

			refetch();
		},
		[createCustomField, refetch, enqueueSnackbar]
	);
	const resolverCreate = classValidatorResolver(CustomFieldInfoDto);

	const handleOpenModalCreate = useCallback(() => {
		openCreateModal({
			resolver: resolverCreate,
			defaultValues: defaultCreateValues,
			submitAction: handleAddCustomField,
			closeModal: closeCreateModal,
			formTitle: t('custom-fields.create-custom-field'),
		});
	}, [openCreateModal, handleAddCustomField]);

	const [updateCustomField] = useUpdateCustomFieldMutation();
	const { openModal: openEditModal, closeModal: closeEditModal } =
		useMuiModal<AddCustomFieldFormProps>(AddCustomFieldForm);

	const handleEditCustomField = useCallback(
		async (updatedCustomField: any) => {
			const updatedCustomFields: CustomFieldInfoDto = { ...updatedCustomField, districtId };

			try {
				await updateCustomField({ id: updatedCustomField._id, updateData: updatedCustomFields }).unwrap();
				enqueueSnackbar('Custom Field updated successfully', { variant: 'success' });
				closeEditModal();
				refetch();
			} catch (error) {
				enqueueSnackbar('Failed to update custom field', { variant: 'error' });
			}
		},
		[updateCustomField]
	);

	const handleOpenModalEdit = useCallback(
		(customField: CustomFieldInfoDto) => {
			const isDefaultType = customField.attributeType === 'Default';
			openEditModal({
				resolver: resolverCreate,
				defaultValues: customField,
				submitAction: handleEditCustomField,
				closeModal: closeEditModal,
				formTitle: t('custom-fields.edit-custom-field'),
				isEditMode: true,
				isDefault: isDefaultType ? true : false,
			});
		},
		[openEditModal, handleEditCustomField]
	);

	const { openModal: openConfirmDeleteModal, closeModal: closeConfirmDeleteModal } =
		useMuiModal<ConfirmationModalProps>(ConfirmationModal);

	const [deleteCustomField] = useDeleteCustomFieldMutation();

	const handleDelete = async (customField: any): Promise<void> => {
		try {
			if (customField._id) {
				await deleteCustomField(customField._id).unwrap();
				enqueueSnackbar('Custom Field deleted successfully', { variant: 'success' });

				refetch();
				closeConfirmDeleteModal();
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleOpenDeleteConfirmModal = useCallback(
		(customField: CustomFieldInfoDto) => {
			const handleSubmitDelete = async () => {
				await handleDelete(customField);
			};
			openConfirmDeleteModal({
				title: t('custom-fields.delete-title'),
				description: t('custom-fields.delete-description').replace(':Custom_fullname', customField.name),
				onSubmit: handleSubmitDelete,
				onClose: closeConfirmDeleteModal,
				icon: <DeleteIcon sx={{ fontSize: 40 }} />,
				customBackgrondColorKey: 'white',
				customColorKey: 'red',
				customBorderColorKey: 'red',
				danger: true,
				leftButtonProps: {
					children: t('families.forms.buttons.cancel'),
					variant: 'secondary',
					contraMode: true,
					sx: buttonModalWindowStyles,
				},
				rightButtonProps: {
					children: t('families.forms.buttons.delete'),
					variant: 'danger',
					contraMode: true,
					sx: buttonModalWindowStyles,
				},
			});
		},
		[handleDelete, openConfirmDeleteModal]
	);

	const scrollRef = useRef<HTMLDivElement>(null);

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<Grid
				container
				alignItems="center"
				spacing={2}
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-end',
					width: '100%',
				}}
			>
				<Grid item xs={2} sx={{ marginLeft: 'auto' }}>
					<CoreButton
						onClick={handleOpenModalCreate}
						sx={{
							display: 'flex',
							padding: '12px 24px',
							fontSize: '16px',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '8px',
							whiteSpace: 'nowrap',
						}}
					>
						<AddIcon /> {t('custom-fields.add-custom')}
					</CoreButton>
				</Grid>
			</Grid>
			<Box sx={{ marginTop: 2, height: '95%', overflowY: 'auto' }} ref={scrollRef}>
				<CustomRequirementsTable
					data={fields || []}
					loading={isFetchingCustomFields}
					sorting={sortingArray}
					onSortingChange={handleSortingChange}
					onDelete={handleOpenDeleteConfirmModal}
					onEdit={handleOpenModalEdit}
				/>
			</Box>
		</Container>
	);
};
