import { useMemo } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { UserDto, UserRolesEnum } from '@families-link/shared';
import { UsersTableHandlers } from '../../components/users-table/users-table.component';
import { UsersTableActions } from '../../components/users-table/users-table-actions.component';
const helper = createColumnHelper<UserDto>();

function getEnumRoleByValue(value: string): string | undefined {
	return (Object.keys(UserRolesEnum) as Array<keyof typeof UserRolesEnum>).find((key) => UserRolesEnum[key] === value);
}

export function useUsersTableColumns(handlers: UsersTableHandlers): ColumnDef<UserDto, string>[] {
	const { onEdit, onMailSend, onResetPassword, onDelete } = handlers;

	const [t] = useTranslation();

	const result = useMemo(
		() => [
			helper.accessor('name', {
				header: t('users.columns.name'),
				meta: {
					flex: 1,
				},
			}) as ColumnDef<UserDto, string>,
			helper.accessor('email', {
				header: t('users.columns.email'),
				meta: {
					flex: 1,
				},
			}) as ColumnDef<UserDto, string>,
			helper.accessor('role', {
				header: t('users.columns.role'),
				meta: {
					flex: 1,
				},
				cell: (info) => getEnumRoleByValue(info.getValue()),
			}) as ColumnDef<UserDto, string>,
			helper.display({
				id: 'actions',
				cell: (props) => (
					<UsersTableActions
						user={props.row.original}
						onEdit={onEdit}
						onDelete={onDelete}
						onMailSend={onMailSend}
						onResetPassword={onResetPassword}
					/>
				),
			}) as ColumnDef<UserDto>,
		],
		[t, onEdit]
	);

	return result;
}
