import { FC } from 'react';
import { TableListQueryResponse } from '../../../_core/interfaces/table-list-query.interface';
import { EngagementDto, PermissionDto } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { CoreTable, CoreTableEmptyBody } from '../../../_core/components/_ui/core-table';
import { useEngagementsTableColumns } from '../../hooks/table-columns/engagements-table-columns.hook';

export interface EngagementsTableHandlers {
	permission?: PermissionDto;
	onDelete?(engagement: EngagementDto): void;
	onEdit?(engagement: EngagementDto): void;
	onView?(engagement: EngagementDto): void;
	onSendEmail?(engagement: EngagementDto): void;
	onSendSMS?(engagement: EngagementDto): void;
	onArchive?(engagement: EngagementDto): void;
	onUnarchive?(engagement: EngagementDto): void;
}

type EngagementsTableProps = TableListQueryResponse<EngagementDto> & EngagementsTableHandlers;

export const EngagementsTable: FC<EngagementsTableProps> = (props) => {
	const {
		data,
		sorting,
		onSortingChange,
		loading,
		hasMore,
		fetchMore,
		scrollRef,
		permission,
		onDelete,
		onEdit,
		onView,
		onSendEmail,
		onSendSMS,
		onArchive,
		onUnarchive,
	} = props;

	const [t] = useTranslation();

	const columns = useEngagementsTableColumns(
		{
			onDelete,
			onEdit,
			onView,
			onSendEmail,
			onSendSMS,
			onArchive,
			onUnarchive,
		},
		permission
	);

	const table = useReactTable<EngagementDto>({
		data,
		columns,
		state: {
			sorting,
		},
		onSortingChange,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<CoreTable
			px={0}
			ref={scrollRef}
			loading={loading}
			hasMore={hasMore}
			threshold={200}
			emptyListComponent={<CoreTableEmptyBody description={t('engagements.no-engagements')} />}
			fetchMore={fetchMore}
			rowModel={table.getRowModel()}
			headerGroups={table.getHeaderGroups()}
		/>
	);
};
