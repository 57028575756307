import { forwardRef, useCallback } from 'react';
import { useSnackbar, SnackbarContent, CustomContentProps } from 'notistack';
import { Card, CardActions, Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { IconCircle } from '../_ui/icon-circle';
import {
	successCardStyles,
	cardActionsStyles,
	textBoxStyles,
	headerStyles,
	messageStyles,
	closeButtonStyles,
} from './notification.styles';
import { useIsMobile } from '../../hooks/useis-mobile.hook';

const CustomSuccess = forwardRef<HTMLDivElement, CustomContentProps>(({ id, ...props }, ref) => {
	const { closeSnackbar } = useSnackbar();
	const isMobile = useIsMobile();
	const handleDismiss = useCallback(() => {
		closeSnackbar(id);
	}, [id, closeSnackbar]);

	return (
		<SnackbarContent ref={ref}>
			<Card sx={successCardStyles}>
				<CardActions sx={cardActionsStyles}>
					<IconCircle customBackgrondColorKey="white" customColorKey="green" size="small">
						<DoneIcon />
					</IconCircle>
					<Box sx={textBoxStyles}>
						<Typography variant="h6" sx={headerStyles}>
							Success
						</Typography>
						{!isMobile && (
							<Typography variant="body2" sx={messageStyles}>
								{props.message}
							</Typography>
						)}
					</Box>
					<Box sx={{ marginLeft: 'auto' }}>
						<IconButton size="small" onClick={handleDismiss}>
							<CloseIcon fontSize="small" sx={closeButtonStyles} />
						</IconButton>
					</Box>
				</CardActions>
			</Card>
		</SnackbarContent>
	);
});

CustomSuccess.displayName = 'Success';

export default CustomSuccess;
