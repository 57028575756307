import {
	CURRENT_DISTRICT_ID_LOCAL_STORAGE_KEY,
	CURRENT_FAMILY_ID_LOCAL_STORAGE_KEY,
	CURRENT_PROVIDER_ID_LOCAL_STORAGE_KEY,
	CURRENT_ENGAGEMENT_ID_LOCAL_STORAGE_KEY,
	CURRENT_SURVEY_TEMPLATE_ID_LOCALE_STORAGE_KEY,
} from '../constants';

export const getLocalStorageItem = (key: string): string | null => localStorage.getItem(key);
export const setLocalStorageItem = (key: string, value: string): void => {
	localStorage.setItem(key, value);
};
export const removeLocalStorageItem = (key: string): void => {
	localStorage.removeItem(key);
};

export const getLocalStorageDistrictId = () => getLocalStorageItem(CURRENT_DISTRICT_ID_LOCAL_STORAGE_KEY);
export const setLocalStorageDistrictId = (value: string) =>
	setLocalStorageItem(CURRENT_DISTRICT_ID_LOCAL_STORAGE_KEY, value);
export const removeLocalStorageDistrictId = () => removeLocalStorageItem(CURRENT_DISTRICT_ID_LOCAL_STORAGE_KEY);

export const getLocalStorageFamilyId = () => getLocalStorageItem(CURRENT_FAMILY_ID_LOCAL_STORAGE_KEY);
export const setLocalStorageFamilyId = (value: string) =>
	setLocalStorageItem(CURRENT_FAMILY_ID_LOCAL_STORAGE_KEY, value);
export const removeLocalStorageFamilyId = () => removeLocalStorageItem(CURRENT_FAMILY_ID_LOCAL_STORAGE_KEY);

export const getLocalStorageProviderId = () => getLocalStorageItem(CURRENT_PROVIDER_ID_LOCAL_STORAGE_KEY);
export const setLocalStorageProviderId = (value: string) =>
	setLocalStorageItem(CURRENT_PROVIDER_ID_LOCAL_STORAGE_KEY, value);
export const removeLocalStorageProviderId = () => removeLocalStorageItem(CURRENT_PROVIDER_ID_LOCAL_STORAGE_KEY);

export const getLocalStorageEngagementId = () => getLocalStorageItem(CURRENT_ENGAGEMENT_ID_LOCAL_STORAGE_KEY);
export const setLocalStorageEngagementId = (value: string) =>
	setLocalStorageItem(CURRENT_ENGAGEMENT_ID_LOCAL_STORAGE_KEY, value);
export const removeLocalStorageEngagementId = () => removeLocalStorageItem(CURRENT_ENGAGEMENT_ID_LOCAL_STORAGE_KEY);

export const getLocalStorageSurveyTemplateId = () => getLocalStorageItem(CURRENT_SURVEY_TEMPLATE_ID_LOCALE_STORAGE_KEY);
export const setLocalStorageSurveyTemplateId = (value: string) =>
	setLocalStorageItem(CURRENT_SURVEY_TEMPLATE_ID_LOCALE_STORAGE_KEY, value);
export const removeLocalStorageSurveyTemplateId = () =>
	removeLocalStorageItem(CURRENT_SURVEY_TEMPLATE_ID_LOCALE_STORAGE_KEY);
