import { useMuiModal } from '../../../_core/hooks';
import { EngagementFormModal, EngagementFormModalProps } from '../../components/modal-form';
import {
	FamilyDto,
	EngagementDto,
	CustomFieldDto,
	ProviderDto,
	FieldDto,
	EmailStatusEnum,
	SurveyStatusEnum,
	EngagementStatusEnum,
	DefaultFieldTypeStatusEnum,
} from '@families-link/shared';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocalStorageDistrictId } from '../../../_core/utils';
import dayjs from 'dayjs';

export type useOpenModalFormHookProps = {
	featchAction(values: any): Promise<void>;
	handlePagination?(): void;
	isCreate: boolean;
	defaultValues?: any;
	submitButtonName?: string;
	toastText?: string;
	fieldsListFromDistrict?: CustomFieldDto[];
	providersList?: ProviderDto[];
	familiesList?: FamilyDto[];
};

export function useOpenModalForm(props: useOpenModalFormHookProps) {
	const currentDistrict = getLocalStorageDistrictId();

	const {
		featchAction,
		handlePagination,
		isCreate = true,
		submitButtonName,
		toastText,
		fieldsListFromDistrict,
		providersList,
		familiesList,
	} = props;

	const initialDefaultValues = {
		districtId: currentDistrict,
		families: [],
		provider: null,
		status: EngagementStatusEnum.New,
		description: '',
		...fieldsListFromDistrict?.reduce((allObj: any, el) => {
			allObj[`field_${el._id}`] = '';
			return allObj;
		}, {}),
		[`field_${fieldsListFromDistrict?.find((el: FieldDto) => el.name === 'Type')?._id}`]:
			DefaultFieldTypeStatusEnum.Project, //
		[`field_${fieldsListFromDistrict?.find((el: FieldDto) => el.name === 'Email status')?._id}`]:
			EmailStatusEnum.NotShared,
		[`field_${fieldsListFromDistrict?.find((el: FieldDto) => el.name === 'Survey status')?._id}`]:
			SurveyStatusEnum.NoSurvey,
		day: null,
		time: null,
		appointmentDuration: '',
	};

	const transformEngagementForForm = (engagement: EngagementDto) => {
		const fieldsList = fieldsListFromDistrict?.length === 0 ? engagement.customFields : fieldsListFromDistrict;
		const fieldDuration = fieldsList?.find((el: FieldDto) => el.name === 'Duration')?._id;
		const fieldType = fieldsList?.find((el: FieldDto) => el.name === 'Type')?._id;
		const valueType = engagement.customFieldsValues.find(
			(item) => item.fieldId.toString() === fieldType?.toString()
		)?.value;
		const transformedFields = engagement.customFieldsValues?.reduce((result: any, el) => {
			if (valueType === DefaultFieldTypeStatusEnum.Appointment) {
				if (el.fieldId.toString() === fieldDuration) {
					result[`day`] = dayjs(el.value.startDate);
					result[`time`] = dayjs(el.value.startDate);
					result[`appointmentDuration`] = dayjs(el.value.endDate).diff(el.value.startDate, 'millisecond');
				} else {
					result[`field_${el.fieldId}`] = el.value;
				}
			} else {
				if (el.fieldId.toString() === fieldDuration) {
					result[`field_${el.fieldId}`] = [el.value.startDate, el.value.endDate];
				} else {
					result[`field_${el.fieldId}`] = el.value;
				}
			}
			return result;
		}, {});
		const transformedEngagement = { ...Object.assign(engagement, {}), ...transformedFields };
		return transformedEngagement;
	};

	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const showToast = (message: string, variant: 'error' | 'default' | 'success' | 'warning' | 'info' | undefined) => {
		enqueueSnackbar(message, {
			variant,
		});
	};

	const { openModal, closeModal, updateCurrentModal } = useMuiModal<EngagementFormModalProps>(EngagementFormModal);

	const handleSubmit = async (values: any): Promise<void> => {
		try {
			await featchAction(values);
			showToast(toastText || 'Successfully performed', 'success');
			if (handlePagination) {
				handlePagination();
			}
			closeModal();
		} catch (error) {
			updateCurrentModal({ formError: error });
		}
	};

	const handleOpenModal = useCallback(
		(engagement?: EngagementDto) => {
			openModal({
				formTitle: isCreate ? t('engagements.forms.create-engagement') : t('engagements.forms.edit-engagement'),
				defaultValues: engagement
					? transformEngagementForForm(engagement)
					: { ...initialDefaultValues, ...props.defaultValues },
				submitAction: handleSubmit,
				closeModal,
				submitButtonName,
				fieldsListFromDistrict,
				providersList,
				familiesList,
			});
		},
		[openModal, handleSubmit]
	);

	return handleOpenModal;
}
