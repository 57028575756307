export const ProfileRoutes = {
	Engagements: '/engagements',
	Root: '/dashboard',
	Profile: '/profile',
	SecuritySettings: '/profile/security',
	Providers: '/providers',
	Districts: '/districts',
	Families: '/families',
	Distribute: '/distribute',
};
