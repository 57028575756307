export enum ServiceTypeStatusEnum {
	Appointment = 'Appointment',
	Project = 'Project',
	Both = 'Both',
}

export const ServiceTypeStatusArray = [
	ServiceTypeStatusEnum.Appointment,
	ServiceTypeStatusEnum.Project,
	ServiceTypeStatusEnum.Both,
];
