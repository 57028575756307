import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { ButtonBase, ButtonBaseProps } from '@mui/material';

export interface CoreActionButtonProps extends ButtonBaseProps {
	variant?: 'danger' | 'primary' | 'secondary';
}

export const CoreActionButton: FC<CoreActionButtonProps> = styled(ButtonBase)<CoreActionButtonProps>(
	({ theme, variant = 'primary' }) => {
		const { transitions, colors, shape } = theme;

		const isPrimary = variant === 'primary';
		const isSecondary = variant === 'secondary';
		const isDanger = variant === 'danger';

		const bgColor = !isDanger ? colors.blue : colors.red;
		const activeColor = !isDanger ? colors.bluePressed : colors.redPressed;

		return {
			width: 40,
			height: 40,
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: isSecondary ? 'transparent' : colors.blueTransparentLight,
			borderRadius: shape.borderRadius,
			color: colors.blue,
			border: '2px solid transparent',
			transition: transitions.create(['background-color', 'color', 'border-color'], {
				duration: transitions.duration.short,
			}),

			'&:hover, &.Mui-focusVisible': {
				borderColor: bgColor,
			},

			'&:active, &.active-button': {
				borderColor: activeColor,
			},

			'&.Mui-focusVisible': {
				borderColor: activeColor,
			},

			'&:disabled': {
				color: '#DCDFE7',
			},
		};
	}
);
