import { IsString, IsBoolean, IsOptional, IsArray, ValidateNested, IsEnum } from 'class-validator';
import { Type } from 'class-transformer';
import { FieldOptionDto } from './field-option.dto';
import { FieldDto } from './field-info.dto';
import { FieldOptionInterface } from '../../interfaces';

export class CustomFieldInfoDto extends FieldDto {
	@IsOptional()
	@IsString()
	districtId?: string;

	@IsOptional()
	@IsString()
	defaultFieldId?: string;

	@IsOptional()
	@IsArray()
	@ValidateNested({ each: true })
	@Type(() => FieldOptionDto)
	options?: Array<FieldOptionInterface>;
}
