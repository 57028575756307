import { FC } from 'react';
import { Modal, Button, Typography, Box } from '@mui/material';
import { CoreModal } from '../../../_core/components/_ui/core-modal';
import { CoreButton, CoreButtonBase } from '../../../_core/components/_ui/core-button';
import { useTranslation } from 'react-i18next';
import { RemoveIcon } from '../../../_core/constants/icons.constants';

interface DeleteProviderModalProps {
	open: boolean;
	onClose: () => void;
	onSubmit: (providerId: string) => Promise<void>;
	providerId: string;
	providerName: string;
}

export const DeleteProviderModal: FC<DeleteProviderModalProps> = ({
	open,
	onClose,
	onSubmit,
	providerName,
	providerId,
}) => {
	const [t] = useTranslation();

	const handleDelete = async () => {
		try {
			await onSubmit(providerId);
			onClose();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<CoreModal open={open} onClose={onClose}>
			<Box
				sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '40px' }}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',

						width: '80px',
						height: '80px',
						borderRadius: '100px',

						border: '1px solid var(--red, #F8223C)',
					}}
				>
					<RemoveIcon />
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', textAlign: 'center' }}>
					<Typography sx={{ fontSize: '20px', fontStyle: 'normal', fontWeight: 'bold', lineHeight: '120%' }}>
						{t('providers.confirm-deletion')}
					</Typography>
					<Typography>
						{t('providers.sure-delete')} {providerName}?
					</Typography>
				</Box>
				<Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<CoreButton variant="secondary" onClick={onClose} sx={{ marginRight: 1, width: '120px' }}>
						{t('providers.cancel')}
					</CoreButton>
					<CoreButton variant="danger" onClick={handleDelete} sx={{ width: '120px' }} color="error">
						{t('providers.delete')}
					</CoreButton>
				</Box>
			</Box>
		</CoreModal>
	);
};
