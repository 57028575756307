import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { DefaultValues, useForm } from 'react-hook-form';
import { ProviderDto } from '@families-link/shared';

type ProviderFilterFormValues = {
	providers: [];
};

export const useProviderFilter = () => {
	const [providers, setProviders] = useState<ProviderDto[]>([]);

	const defaultValues: DefaultValues<ProviderFilterFormValues> = {
		providers: [],
	};
	const { register, getValues, control, watch } = useForm({
		defaultValues,
	});
	const filter = watch('providers');
	const debouncedUpdate = useCallback(
		debounce((list?: ProviderDto[]) => {
			setProviders(list || []);
		}, 300),
		[]
	);

	useEffect(() => {
		debouncedUpdate(filter);
	}, [filter, debouncedUpdate]);

	return {
		register,
		getValues,
		control,
		providers: providers.map((el) => el._id),
	};
};
