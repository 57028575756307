import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from '../../../store';
import providersApi from '../../../store/api/providers.api';
import { ProviderDto } from '@families-link/shared';

export type ProviderState = { currentProvider: ProviderDto | null };

export const initialState = {
	currentProvider: null,
};

export const providerSlice = createSlice({
	name: 'provider',
	initialState,
	reducers: {
		clearCurrentProvider: (state: ProviderState) => {
			state.currentProvider = null;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(providersApi.endpoints.getProviderById.matchFulfilled, (state: ProviderState, action) => {
			state.currentProvider = action.payload;
		});
	},
});

export const { clearCurrentProvider } = providerSlice.actions;

export const useCurrentProviderSelector = () => useAppSelector((state) => state.provider.currentProvider);
