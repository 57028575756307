import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export const EngagementLineChart = () => {
	const mockData = [
		{ month: 'Jan 2024', engagements: 230 },
		{ month: 'Feb 2024', engagements: 145 },
		{ month: 'Mar 2024', engagements: 260 },
		{ month: 'Apr 2024', engagements: 150 },
		{ month: 'May 2024', engagements: 280 },
		{ month: 'June 2024', engagements: 70 },
	];

	return (
		<ResponsiveContainer width="100%" height="100%">
			<LineChart data={mockData} margin={{ right: 30, left: 20, bottom: 5 }}>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="month" />
				<YAxis tickLine={{ stroke: '#8884d8' }} />
				<Tooltip itemStyle={{ color: '#333' }} />
				<Legend
					layout="horizontal"
					verticalAlign="top"
					align="right"
					wrapperStyle={{
						fontStyle: 'bold',
					}}
				/>
				<Line type="linear" dataKey="engagements" stroke="#F89377" activeDot={{ r: 8 }} />
			</LineChart>
		</ResponsiveContainer>
	);
};
