import { IsEnum, IsOptional } from 'class-validator';
import { OrderEnum } from '../../enums';

export class SortingOptionsDto {
	@IsEnum(OrderEnum)
	@IsOptional()
	readonly order?: OrderEnum = OrderEnum.ASC;

	@IsOptional()
	readonly orderField?: string;
}
