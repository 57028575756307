import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../store';
import { initiateDistrict } from '../../district/store/actions/initiate-distrrict-state.action';
import { initiateFamily } from '../../family/store/actions/initiate-family-state.action';
import { initiateProvider } from '../../providers/store/actions/initiate-provider-state';
import { initiateEngagement } from '../../engagement/store/actions/initiate-engagement-state.action';

type StateKey = 'district' | 'family' | 'provider' | 'engagement';

export const useInitiateState = (key: StateKey) => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		switch (key) {
			case 'district':
				dispatch(initiateDistrict());
				break;
			case 'family':
				dispatch(initiateFamily());
				dispatch(initiateDistrict());
				break;
			case 'provider':
				dispatch(initiateProvider());
				dispatch(initiateDistrict());
				break;
			case 'engagement':
				dispatch(initiateEngagement());
				dispatch(initiateDistrict());
				break;
			default:
				break;
		}
	}, [key]);
};
