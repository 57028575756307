import { useMuiModal } from '../../../_core/hooks';
import { UserFormModal, UserFormModalProps } from '../../components/modal-form';
import { CreateUserDto, UpdateUserDto, UserDto } from '@families-link/shared';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocalStorageDistrictId } from '../../../_core/utils';

export type useOpenModalFormHookProps = {
	featchAction(values: CreateUserDto | UpdateUserDto): Promise<void>;
	handlePagination(): void;
	isCreate: boolean;
	defaultValues?: any;
	submitButtonName?: string;
	toastText?: string;
};

export function useOpenModalForm(props: useOpenModalFormHookProps) {
	const initialDefaultValues = {
		name: '',
		email: '',
		role: '',
		active: false,
		districtId: getLocalStorageDistrictId(),
	};

	const {
		featchAction,
		handlePagination,
		isCreate = true,
		defaultValues = initialDefaultValues,
		submitButtonName,
		toastText,
	} = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const showToast = (message: string, variant: 'error' | 'default' | 'success' | 'warning' | 'info' | undefined) => {
		enqueueSnackbar(message, {
			variant,
		});
	};

	const { openModal, closeModal, updateCurrentModal } = useMuiModal<UserFormModalProps>(UserFormModal);

	const handleSubmit = async (values: CreateUserDto | UpdateUserDto): Promise<void> => {
		try {
			await featchAction(values);
			showToast(toastText || 'Successfully performed', 'success');
			handlePagination();
			closeModal();
		} catch (error) {
			updateCurrentModal({ formError: error });
		}
	};

	const resolver = isCreate ? classValidatorResolver(CreateUserDto) : classValidatorResolver(UpdateUserDto);
	const handleOpenModal = useCallback(
		(user?: UserDto) => {
			openModal({
				formTitle: isCreate ? t('users.forms.create-user') : t('users.forms.edit-user'),
				resolver,
				defaultValues: user ? user : defaultValues,
				submitAction: handleSubmit,
				closeModal,
				submitButtonName,
			});
		},
		[openModal, handleSubmit]
	);

	return handleOpenModal;
}
