import { SxProps, Theme } from '@mui/material';

export const modalBoxStyles: SxProps<Theme> = [
	{
		m: 2,
	},
	({ breakpoints }) => ({
		[breakpoints.down('sm')]: {
			m: 1,
		},
	}),
];

export const containerFormStyles: SxProps<Theme> = [
	{
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	({ breakpoints }) => ({
		[breakpoints.down('sm')]: {
			flexDirection: 'column',
			justifyContent: 'flex-start',
		},
	}),
];

export const inputBoxStyles: SxProps<Theme> = [
	{
		my: 2,
	},
	({ breakpoints }) => ({
		[breakpoints.down('sm')]: {
			my: 1,
		},
	}),
];

export const containerButtonStyles: SxProps<Theme> = [
	{
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
];

export const errorTextStyles: SxProps<Theme> = [
	{
		fontWeight: 'bold',
		color: (theme) => theme.colors.red,
		pb: 2,
	},
];

export const leftButtonStyles: SxProps<Theme> = [
	{
		mr: 2,
	},
	({ breakpoints }) => ({
		[breakpoints.down('sm')]: {
			mr: 1,
			p: 0.5,
		},
	}),
];
