import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SurveyQuestionInterface } from '@families-link/shared';
import { CoreModal } from '../../../_core/components/_ui/core-modal';
import { SurveyQuestionRender } from '../question/question.component';

interface SurveyModalProps {
	modalTitle: string;
	questions: SurveyQuestionInterface[];
	open: boolean;
	onClose: () => void;
}

export const SurveyModal: React.FC<SurveyModalProps> = ({ modalTitle, questions, open, onClose }) => {
	const { t } = useTranslation();

	return (
		<CoreModal modalTitle={modalTitle} open={open} onClose={onClose}>
			<Box>
				{questions?.map((question, index) => (
					<Box key={question.text} sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
						<Typography fontSize={14} sx={{ fontWeight: '500', mb: '10px' }}>
							{index + 1}. {question.text}
						</Typography>
						<SurveyQuestionRender question={question} />
					</Box>
				))}
			</Box>
		</CoreModal>
	);
};
