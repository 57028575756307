import React, { FC, useEffect } from 'react';
import { useClearStore } from '../../../_core/hooks';
import { EngagementsListPage } from './engagements-list.page';

export const EngagementsListAdminLevelPage: FC = () => {
	const clearStore = useClearStore();

	useEffect(() => {
		clearStore();
	}, []);

	return <EngagementsListPage permission={{ read: true }} />;
};
