export enum EngagementStatusEnum {
	New = 'New',
	InProgress = 'In progress',
	Confirmed = 'Confirmed',
	Completed = 'Completed',
}

export const EngagementStatusArray = [
	EngagementStatusEnum.New,
	EngagementStatusEnum.InProgress,
	EngagementStatusEnum.Confirmed,
	EngagementStatusEnum.Completed,
];

export const EngagementStatusColorEnum = {
	[EngagementStatusEnum.New]: '#EEE764',
	[EngagementStatusEnum.InProgress]: '#93E9B3',
	[EngagementStatusEnum.Confirmed]: '#78BBE2',
	[EngagementStatusEnum.Completed]: '#F89377',
};
