import { FC } from 'react';
import { TableListQueryResponse } from '../../../_core/interfaces/table-list-query.interface';
import { DistrictDto } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { CoreTable, CoreTableEmptyBody } from '../../../_core/components/_ui/core-table';
import { useDistrictsTableColumns } from '../../hooks/table-columns/districts-table-columns.hook';

export interface DistrictsTableHandlers {
	onDelete(district: DistrictDto): void;
	onEdit(district: DistrictDto): void;
	onView(district: DistrictDto): void;
}

type DistrictsTableProps = TableListQueryResponse<DistrictDto> & DistrictsTableHandlers;

export const DistrictsTable: FC<DistrictsTableProps> = (props) => {
	const { data, sorting, onSortingChange, loading, hasMore, fetchMore, scrollRef, onDelete, onEdit, onView } = props;

	const [t] = useTranslation();

	const columns = useDistrictsTableColumns({
		onDelete,
		onEdit,
		onView,
	});

	const table = useReactTable<DistrictDto>({
		data,
		columns,
		state: {
			sorting,
		},
		onSortingChange,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<CoreTable
			px={0}
			ref={scrollRef}
			loading={loading}
			hasMore={hasMore}
			threshold={200}
			emptyListComponent={
				<CoreTableEmptyBody label={t('profile.sessions.table.empty-title')} description={t('districts.no-districts')} />
			}
			fetchMore={fetchMore}
			rowModel={table.getRowModel()}
			headerGroups={table.getHeaderGroups()}
		/>
	);
};
