export enum SurveyQuestionTypeEnum {
	ShortText = 'Short text',
	Options = 'Options',
	LongText = 'Long text',
	MultiSelect = 'MultiSelect',
}

export const SurveyQuestionTypeArray = [
	SurveyQuestionTypeEnum.ShortText,
	SurveyQuestionTypeEnum.Options,
	SurveyQuestionTypeEnum.LongText,
	SurveyQuestionTypeEnum.MultiSelect,
];
