import React, { useCallback, useEffect, useState } from 'react';
import { FieldError, FieldValues, ResolverOptions, useForm } from 'react-hook-form';
import { Box, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { LanguageEnum, ProviderDto, WorkStatusEnum, ServiceTypeStatusEnum } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import { CoreSelect } from '../../../_core/components/_ui/core-select';
import { InHouse, InternationalFlag, Outsource, Verified } from '../../../_core/constants/icons.constants';
import UploadPhoto from '../../../_core/components/upload-photo/upload-photo.component';
import { CoreAutoComplete } from '../../../_core/components/_ui/core-autocomplete';
import { CoreModal, CoreModalProps } from '../../../_core/components/_ui/core-modal';
import { getObjectLanguage } from '../../../_core/components/languages-component';
import { buttonModalWindowStyles, mainTitleModalWindowStyles } from '../../../_core/styles';
import { mergeSx } from 'merge-sx';

export type EditProviderFormProps = CoreModalProps & {
	resolver?: <TFieldValues extends FieldValues, TContext>(
		values: TFieldValues,
		context: TContext | undefined,
		options: ResolverOptions<TFieldValues>
	) => any;
	formError?: any;
	defaultValues?: any;
	submitAction(formData: FormData): Promise<void>;
	onClose: () => void;
};

export const EditProviderForm: React.FC<EditProviderFormProps> = ({
	onClose,
	formError,
	resolver,
	defaultValues,
	submitAction,
	...modalProps
}) => {
	const {
		register,
		handleSubmit,
		getValues,
		control,
		reset,
		setError,
		formState: { errors, isDirty, isLoading, isSubmitting, isSubmitted, isValid },
	} = useForm({
		resolver,
		defaultValues,
		mode: 'all',
	});

	useEffect(() => {
		reset(defaultValues);
	}, [defaultValues, reset]);

	useEffect(() => {
		if (errors['targetLanguage']) {
			setError('targetLanguage', {
				type: 'manual',
				message: 'Target language is required',
			});
		}
	}, [setError, errors['targetLanguage']]);

	const disableSubmit = !isValid && (isDirty || isSubmitted);
	const loadingSubmit = isLoading || isSubmitting;

	const [isPhotoDeleted, setIsPhotoDeleted] = useState(false);

	const [t] = useTranslation();
	const [selectedFile, setSelectedFile] = useState<File | null>(null);

	const handleFormSubmit = useCallback(
		async (values: FieldValues) => {
			const providerValues = values as ProviderDto;
			const formData = new FormData();

			Object.keys(providerValues).forEach((key) => {
				const value = (providerValues as any)[key];
				if (value !== undefined && value !== null) {
					formData.append(key, value);
				}
			});

			if (selectedFile) {
				formData.append('file', selectedFile);
			}

			if (selectedFile === null && isPhotoDeleted === true) {
				formData.delete('imageUrl');
				formData.append('imageUrl', '');
			}

			try {
				await submitAction(formData);
				onClose();
			} catch (error) {
				console.error(error);
			}
		},
		[submitAction, setError, selectedFile, onClose, isPhotoDeleted]
	);

	const handleFileChange = (file: File | null) => {
		if (file) {
			setSelectedFile(file);
		} else {
			setIsPhotoDeleted(true);
			setSelectedFile(null);
		}
	};

	return (
		<CoreModal onClose={onClose} {...modalProps} bodySx={{ pt: 0, px: 5 }} paperSx={{ width: '100%' }}>
			<Typography variant="h5" sx={mergeSx(mainTitleModalWindowStyles, { mb: 5 })}>
				{t('providers.edit-provider')}
			</Typography>
			<Box
				component="form"
				onSubmit={handleSubmit(handleFormSubmit)}
				sx={{ display: 'flex', flexDirection: 'row', gap: '40px' }}
			>
				<Box sx={{ width: '236px', maxHeight: 364 }}>
					<UploadPhoto onFileChange={handleFileChange} editable file={defaultValues.imageUrl ?? null} />
				</Box>

				<Box>
					<Box sx={{ display: 'flex', flexDirection: 'column', width: '360px', gap: '24px', mb: '40px' }}>
						<CoreTextField
							label={t('providers.full-name')}
							{...register('fullName')}
							placeholder={t('providers.placeholders.full-name') ?? ''}
							requiredMark
							fullWidth
							error={!!errors['fullName']}
							helperText={errors['fullName'] ? (errors['fullName'] as FieldError).message : undefined}
						/>
						{formError && (
							<Typography>
								{formError?.data?.message ? formError.data.message : t('errors.invalid-credentials-error')}
							</Typography>
						)}

						<CoreAutoComplete
							label={t('providers.target-lang')}
							requiredMark
							fullWidth
							multiple
							{...register('targetLanguage')}
							error={!!errors['targetLanguage']}
							helperText={errors['targetLanguage'] ? (errors['targetLanguage'] as FieldError).message : undefined}
							options={Object.values(LanguageEnum)}
							control={control}
							getOptionLabel={(option) => {
								const language = getObjectLanguage(option);
								return language ? language.name : '';
							}}
							selectOnFocus={true}
							isOptionEqualToValue={(option, value) => option === value}
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder={t('providers.placeholders.prefered-language') ?? ''}
									sx={{
										'& .MuiOutlinedInput-root': {
											'&.Mui-focused fieldset': {
												borderColor: (theme) => theme.colors.blue,
											},
										},
									}}
								/>
							)}
							renderOption={(props, option) => {
								const language = getObjectLanguage(option);
								return (
									<MenuItem {...props} key={language?.value}>
										{language?.label}
									</MenuItem>
								);
							}}
						/>
						<CoreSelect
							label={t('providers.verification-type')}
							{...register('verificationType')}
							requiredMark
							defaultValue={defaultValues.verificationType.toString()}
							placeholder={t('providers.placeholders.options') ?? ''}
						>
							<MenuItem value="true">
								{t('providers.verified')} <Verified style={{ marginLeft: '8px' }} />
							</MenuItem>
							<MenuItem value="false">{t('providers.no-verified')}</MenuItem>
						</CoreSelect>

						<CoreSelect
							label={t('providers.work-status')}
							{...register('workStatus')}
							requiredMark
							placeholder={t('providers.placeholders.options') ?? ''}
							defaultValue={defaultValues.workStatus}
						>
							<MenuItem value={WorkStatusEnum.InHouse}>
								{t('providers.work-in-house')}
								<InHouse style={{ marginLeft: '8px' }} />
							</MenuItem>
							<MenuItem value={WorkStatusEnum.Outsource}>
								{t('providers.work-outsource')}
								<Outsource style={{ marginLeft: '8px' }} />
							</MenuItem>
						</CoreSelect>

						<CoreSelect
							label={'Service Type'}
							id="serviceType"
							{...register('serviceType')}
							placeholder={t('providers.placeholders.options') ?? ''}
							fullWidth
							requiredMark
							defaultValue={defaultValues.serviceType}
						>
							<MenuItem value={ServiceTypeStatusEnum.Appointment}>Appointment</MenuItem>
							<MenuItem value={ServiceTypeStatusEnum.Project}>Project</MenuItem>
							<MenuItem value={ServiceTypeStatusEnum.Both}>Both</MenuItem>
						</CoreSelect>
					</Box>

					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item>
							<CoreButton variant="secondary" contraMode onClick={onClose} sx={buttonModalWindowStyles}>
								{t('providers.close')}
							</CoreButton>
						</Grid>
						<Grid item>
							<CoreButton type="submit" contraMode disabled={disableSubmit} loading={loadingSubmit}>
								{t('providers.save-changes')}
							</CoreButton>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</CoreModal>
	);
};
