import {
	DistrictDto,
	PageDto,
	SourceFilter,
	PageOptionsDto,
	CreateDistrictDto,
	UpdateDistrictDto,
} from '@families-link/shared';
import api from '.';

const districtsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getDistrictList: builder.query<PageDto<DistrictDto>, PageOptionsDto & SourceFilter>({
			query: ({ page, take, orderField, order, source }) => ({
				url: `districts`,
				params: { page, take, orderField, order, source },
			}),
		}),
		getDistrictListWithoutPagination: builder.query<DistrictDto[], void>({
			query: () => ({
				url: `districts/all`,
			}),
		}),
		getDistrict: builder.query<DistrictDto, { id?: string | null }>({
			query: ({ id }) => ({
				url: `districts/${id}`,
			}),
		}),
		getDistrictWithoutInitiate: builder.query<DistrictDto, { id?: string | null }>({
			query: ({ id }) => ({
				url: `districts/${id}/without-initiate`,
			}),
		}),
		createDistrict: builder.mutation<DistrictDto, CreateDistrictDto>({
			query: (data) => ({
				url: `districts`,
				method: 'POST',
				body: data,
			}),
		}),
		updateDistrict: builder.mutation<DistrictDto, { id: string; data: UpdateDistrictDto }>({
			query: ({ id, data }) => ({
				url: `districts/${id}`,
				method: 'PUT',
				body: data,
			}),
		}),
		deleteDistrict: builder.mutation<DistrictDto, { id: string }>({
			query: ({ id }) => ({
				url: `districts/${id}`,
				method: 'DELETE',
			}),
		}),
	}),
});

export default districtsApi;

export const {
	useGetDistrictListQuery,
	useGetDistrictQuery,
	useGetDistrictListWithoutPaginationQuery,
	useCreateDistrictMutation,
	useUpdateDistrictMutation,
	useDeleteDistrictMutation,
	useGetDistrictWithoutInitiateQuery,
} = districtsApi;
