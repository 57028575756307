import React, { FC, forwardRef, Ref, useState } from 'react';
import { CoreFormControl, CoreFormControlProps } from '../core-form-control';
import styled from 'styled-components';
import { DateRangePicker, DateRangePickerProps } from '@wojtekmaj/react-daterange-picker';
import EventIcon from '@mui/icons-material/Event';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import './custom-daterange.style.css';
import { Box } from '@mui/material';

export interface CoreDateRangePickerProps extends CoreFormControlProps, DateRangePickerProps {
	small?: boolean;
	clean?: boolean;
	wrapperRef?: Ref<unknown>;
	dateRangeCustomClass?: string;
}

export const CoreStyledDateRangePicker: FC<CoreDateRangePickerProps> = styled(
	DateRangePicker
)<CoreDateRangePickerProps>(({ theme, small, clean }) => ({
	'.react-daterange-picker__wrapper': {
		fontSize: small ? '8px' : '12px',
	},
	'.react-daterange-picker__clear-button': {
		opacity: clean ? 0 : 100,
		pointerEvents: clean ? 'none' : 'auto',
	},
}));

export const CoreDateRangePicker = forwardRef<unknown, CoreDateRangePickerProps>((props, ref) => {
	const {
		id,
		fullWidth,
		controlSx,
		label,
		helperText,
		error,
		disabled,
		focused,
		wrapperRef,
		requiredMark,
		dateRangeCustomClass,
		...inputProps
	} = props;

	console.log(dateRangeCustomClass);

	const [manualFocused, setManualFocused] = useState(false);

	return (
		<CoreFormControl
			disabled={disabled}
			error={error}
			helperText={helperText}
			label={label}
			id={id}
			requiredMark={requiredMark}
			focused={manualFocused}
			fullWidth={fullWidth}
			controlSx={controlSx}
		>
			<Box
				onFocus={() => {
					setManualFocused(true);
				}}
				onBlur={() => {
					setManualFocused(false);
				}}
			>
				<CoreStyledDateRangePicker
					locale="en"
					calendarIcon={<EventIcon color="action" fontSize="small" />}
					calendarProps={{ calendarType: 'gregory' }}
					dayPlaceholder="DD"
					monthPlaceholder="MM"
					yearPlaceholder="YYYY"
					{...inputProps}
					className={dateRangeCustomClass}
				/>
			</Box>
		</CoreFormControl>
	);
});
