import { SurveyTemplateDto } from '@families-link/shared';
import { FC, useCallback, useMemo } from 'react';
import { CoreTableActionsCell, CellAction } from '../../../_core/components/_ui/core-table';
import { SurveyTemplatesTableHandlers } from './survey-templates-table.component';
import { DeleteIcon, EditIcon, EyeIcon } from '../../../_core/components/icons';

export interface SurveyTemplatesTableActionsProps extends SurveyTemplatesTableHandlers {
	surveyTemplate: SurveyTemplateDto;
}

export const SurveyTemplatesTableActions: FC<SurveyTemplatesTableActionsProps> = (props) => {
	const { surveyTemplate, onEdit, onDelete, onView } = props;

	const handleDeleteSurveyTemplate = useCallback(() => {
		if (!surveyTemplate) {
			return;
		}

		onDelete?.(surveyTemplate);
	}, [onDelete, surveyTemplate]);

	const handleEditSurveyTemplate = useCallback(() => {
		if (!surveyTemplate) {
			return;
		}

		onEdit?.(surveyTemplate);
	}, [onEdit, surveyTemplate]);

	const handleViewSurveyTemplate = useCallback(() => {
		if (!surveyTemplate) {
			return;
		}

		onView?.(surveyTemplate);
	}, [onView, surveyTemplate]);

	const actions = useMemo<CellAction[]>(
		() => [
			{
				id: 'view',
				type: 'button',
				props: {
					children: <EyeIcon sx={{ fontSize: 30 }} color="primary" />,
					onClick: handleViewSurveyTemplate,
				},
			},
			{
				id: 'more',
				type: 'menu',
				props: {
					options: [
						{
							id: 'edit',
							label: 'Edit',
							icon: <EditIcon color="primary" />,
							onClick: handleEditSurveyTemplate,
						},
						{
							id: 'delete',
							label: 'Delete',
							icon: <DeleteIcon color="primary" />,
							onClick: handleDeleteSurveyTemplate,
						},
					],
				},
			},
		],
		[handleEditSurveyTemplate, handleViewSurveyTemplate, handleDeleteSurveyTemplate]
	);

	return <CoreTableActionsCell actions={actions} />;
};
