import React from 'react';
import { Box, FormControlLabel, Radio, RadioGroup, Checkbox, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { SurveyQuestionInterface, SurveyQuestionTypeEnum } from '@families-link/shared';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import { useTranslation } from 'react-i18next';

interface SurveyQuestionRenderProps {
	question: SurveyQuestionInterface;
}

export const SurveyQuestionRender: React.FC<SurveyQuestionRenderProps> = ({ question }) => {
	const { _id, text, type, options } = question;
	const [t] = useTranslation();

	const { control, register } = useFormContext();

	switch (type) {
		case SurveyQuestionTypeEnum.ShortText:
			return (
				<CoreTextField
					{...register(_id ? `field_${_id}` : text)}
					fullWidth
					placeholder={t('survey-templates.forms.placeholders.survey-answer') ?? ''}
				/>
			);
		case SurveyQuestionTypeEnum.LongText:
			return (
				<CoreTextField
					{...register(_id ? `field_${_id}` : text)}
					fullWidth
					multiline
					placeholder={t('survey-templates.forms.placeholders.survey-answer') ?? ''}
				/>
			);
		case SurveyQuestionTypeEnum.Options:
			return (
				<Controller
					name={_id ? `field_${_id}` : text}
					control={control}
					defaultValue=""
					render={({ field }) => (
						<RadioGroup {...field} value={field.value || ''} onChange={(e) => field.onChange(e.target.value)}>
							{options?.map((option, index) => (
								<FormControlLabel key={index} value={option.text} control={<Radio />} label={option.text} />
							))}
						</RadioGroup>
					)}
				/>
			);
		case SurveyQuestionTypeEnum.MultiSelect:
			return (
				<Controller
					name={_id ? `field_${_id}` : text}
					control={control}
					defaultValue={[]}
					render={({ field }) => (
						<Box>
							{options?.map((option, index) => (
								<FormControlLabel
									key={index}
									control={
										<Checkbox
											{...field}
											value={option.text}
											checked={field.value.includes(option.text)}
											onChange={(e) => {
												const newValue = e.target.checked
													? [...field.value, option.text]
													: field.value.filter((val: string) => val !== option.text);
												field.onChange(newValue);
											}}
										/>
									}
									label={option.text}
								/>
							))}
						</Box>
					)}
				/>
			);
		default:
			return null;
	}
};
