import { DistrictDto } from '@families-link/shared';
import { FC, useCallback, useMemo } from 'react';
import { CoreTableActionsCell, CellAction } from '../../../_core/components/_ui/core-table';
import { DistrictsTableHandlers } from './districts-table.component';
import { DeleteIcon, EditIcon, EyeIcon } from '../../../_core/components/icons';
export interface SessionsTableActionsProps extends DistrictsTableHandlers {
	district: DistrictDto;
}

export const DistrictsTableActions: FC<SessionsTableActionsProps> = (props) => {
	const { district, onDelete, onEdit, onView } = props;

	const handleDeleteDistrict = useCallback(() => {
		if (!district) {
			return;
		}

		onDelete?.(district);
	}, [onDelete, district]);

	const handleEditDistrict = useCallback(() => {
		if (!district) {
			return;
		}

		onEdit?.(district);
	}, [onEdit, district]);

	const handleViewDistrict = useCallback(() => {
		if (!district) {
			return;
		}

		onView?.(district);
	}, [onView, district]);

	const actions = useMemo<CellAction[]>(
		() => [
			{
				id: 'view',
				type: 'button',
				props: {
					children: <EyeIcon sx={{ fontSize: 30 }} color="primary" />,
					onClick: handleViewDistrict,
				},
			},
			{
				id: 'more',
				type: 'menu',
				props: {
					options: [
						{
							id: 'edit',
							label: 'Edit',
							icon: <EditIcon color="primary" />,
							onClick: handleEditDistrict,
						},
						{
							id: 'delete',
							label: 'Delete',
							icon: <DeleteIcon color="primary" />,
							onClick: handleDeleteDistrict,
						},
					],
				},
			},
		],
		[handleViewDistrict, handleEditDistrict, handleDeleteDistrict]
	);

	return <CoreTableActionsCell actions={actions} />;
};
