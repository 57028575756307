export const DistrictRoutes = {
	Root: '/district/:districtId',
	Dashboard: '/district/:districtId/dashboard',
	Engagements: {
		Root: '/district/:districtId/engagements',
		Details: '/engagements/:engagementId',
	},
	Families: {
		Root: '/district/:districtId/families',
		Detailes: '/families/:familyId',
	},
	Providers: {
		Root: '/district/:districtId/providers',
		Details: '/providers/:providerId',
	},
	CustomRequirements: '/district/:districtId/custom-requirements',
	SurveyTemplates: {
		Root: '/district/:districtId/survey-template',
		Creating: '/district/:districtId/survey-template/creating',
		Editing: '/district/:districtId/survey-template/editing/:surveyTemplateId',
		Preview: '/survey-templates/preview',
		Details: '/survey-templates/preview/:surveyTemplateId',
	},
	Survey: '/surveys/:surveyTemplateId',
	SurveyManualEditing: '/surveys/manual-editing/:surveyTemplateId',
	UserManagment: '/district/:districtId/user-managment',
};
