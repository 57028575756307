import React, { FC, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { ALLOWED_FILE_MIME_TYPES, CustomFieldDto, MAX_FILE_SIZE } from '@families-link/shared';
import { useDropzone } from 'react-dropzone';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { CoreFormControl, CoreFormControlProps } from '../../../_core/components/_ui/core-form-control';
import { UploadIcon } from '../../../_core/constants/icons.constants';
import CoreFileItemComponent from '../../../_core/components/_ui/core-file/core-file-item.component';
import { CoreButton } from '../../../_core/components/_ui/core-button';

export interface DynamicFileFieldProps extends CoreFormControlProps {
	customField: CustomFieldDto;
}

export const DynamicFileField: FC<DynamicFileFieldProps> = (props) => {
	const { customField, controlSx } = props;
	const inputName = `field_${customField._id}`;
	const {
		control,
		watch,
		setValue,
		trigger,
		formState: { errors },
	} = useFormContext();
	const onDrop = useCallback(
		(acceptedFiles: any) => {
			const file = acceptedFiles[0];
			setValue(inputName, file);
			trigger(inputName);
		},
		[setValue, inputName, trigger]
	);
	const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
		onDrop,
		multiple: false,
		noClick: true,
		accept: {
			'image/*': ['.jpeg', '.png', '.jpg', '.gif', '.webp', '.svg'],
			'video/*': ['.mp4', '.mkv', '.avi'],
			'text/*': ['.txt', '.csv'],
			'application/pdf': ['.pdf'],
			'application/msword': ['.doc'],
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
			'application/vnd.ms-excel': ['.xls'],
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls'],
			'application/vnd.ms-powerpoint': ['.ppt'],
			'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
		},
	});
	const file = watch(inputName);

	return (
		<Controller
			name={inputName}
			control={control}
			rules={{
				validate: (value) => validateFile(value, customField.isRequired),
			}}
			render={({ field }) => (
				<CoreFormControl
					label={customField.name || ''}
					requiredMark={customField.isRequired}
					fullWidth={true}
					error={!!errors[inputName]}
					helperText={errors[inputName] ? (errors[inputName] as FieldError).message : undefined}
					controlSx={controlSx}
				>
					<Box
						{...getRootProps()}
						sx={{
							border: '2px dashed #cccccc',
							borderRadius: '10px',
							textAlign: 'center',
							padding: '20px',
							bgcolor: ({ colors }) => (isDragActive ? colors.blueTransparentLight : colors.white),
							transition: 'background-color 0.3s',
						}}
					>
						<input {...getInputProps()} />
						<Box component={UploadIcon} my={1} />
						{isDragActive ? (
							<Typography variant="body2">Drop the file here...</Typography>
						) : file ? (
							<CoreFileItemComponent
								id={inputName}
								type={file.type}
								name={file.name}
								completed
								onCancel={(id) => {
									setValue(id, null);
									trigger(id);
								}}
							/>
						) : (
							<Typography variant="body2">{'Drag and Drop file here (Maximum file size is 5Gb)'}</Typography>
						)}
						<CoreButton variant="secondary" onClick={open} sx={{ my: 2 }}>
							Choose file
						</CoreButton>
					</Box>
				</CoreFormControl>
			)}
		/>
	);
};

const validateFile = (value: any, isRequired: boolean) => {
	if (isRequired && !value) {
		return `File is required`;
	}
	if (value && typeof value !== 'string') {
		if (!ALLOWED_FILE_MIME_TYPES.includes(value.type)) {
			return 'Invalid file type.';
		}
		if (value.size > MAX_FILE_SIZE) {
			return 'File size exceeds 5 GB.';
		}
	}
	return true;
};
