import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLocalStorageProviderId } from '../../../_core/utils';
import providersApi from '../../../../store/api/providers.api';

export const initiateProvider = createAsyncThunk('providers/initiateProvider', async (_, { dispatch }) => {
	const currentProviderId = getLocalStorageProviderId();
	if (currentProviderId) {
		await dispatch(providersApi.endpoints.getProviderById.initiate(currentProviderId, { forceRefetch: true }));
	}
});
