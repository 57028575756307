import React, { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export type ProtectedRouteProps = {
	allowedRoles: string[];
	userRole?: string;
	redirectPath?: string;
};

export const ProtectedRoute: FC<ProtectedRouteProps> = (props) => {
	const { allowedRoles, userRole, redirectPath = '/forbidden' } = props;
	if (userRole && !allowedRoles.includes(userRole)) {
		return <Navigate to={redirectPath} replace />;
	}

	return <Outlet />;
};
