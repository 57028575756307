import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { Controller, FieldError, useFieldArray, useFormContext } from 'react-hook-form';
import { CoreIconButton } from '../../../_core/components/_ui/core-button';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

export type QuestionOptionsProps = {
	questionIndex: number;
};

export const QuestionOptionsArray: FC<QuestionOptionsProps> = (props) => {
	const { questionIndex } = props;
	const [t] = useTranslation();
	const {
		control,
		formState: { errors },
	} = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: `questions.${questionIndex}.options`,
	});

	return (
		<Box sx={{ marginTop: '16px' }}>
			{fields.map((field, index) => (
				<Box key={field.id} sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
					<Controller
						name={`questions.${questionIndex}.options.${index}.text`}
						control={control}
						render={({ field }) => (
							<CoreTextField
								{...field}
								fullWidth
								placeholder={t('survey-templates.forms.placeholders.enter-description')}
								error={!!errors[`questions.${questionIndex}.options.${index}.text`]}
								helperText={
									errors[`questions.${questionIndex}.options.${index}.text`]
										? (errors[`questions.${questionIndex}.options.${index}.text`] as FieldError).message
										: undefined
								}
							/>
						)}
					/>
					<CoreIconButton onClick={() => remove(index)} sx={{ marginLeft: '16px' }}>
						<CloseIcon />
					</CoreIconButton>
				</Box>
			))}
			<Button onClick={() => append({ text: '' })} variant="text">
				<AddIcon />
				{t('survey-templates.forms.buttons.add-option')}
			</Button>
		</Box>
	);
};
