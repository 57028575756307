import React, { FC, useEffect } from 'react';
import { useClearStore } from '../../../_core/hooks';
import { FamiliesListPage } from './family-list.page';

export const FamiliesListAdminLevelPage: FC = () => {
	const clearStore = useClearStore();

	useEffect(() => {
		clearStore();
	}, []);

	return <FamiliesListPage permission={{ read: true }} />;
};
