import {
	FamilyDto,
	PageDto,
	SourceFilter,
	PageOptionsDto,
	LanguageFilter,
	DistrictFilter,
} from '@families-link/shared';
import api from '.';

const familiesApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getFamiliesList: builder.query<
			PageDto<FamilyDto>,
			PageOptionsDto & SourceFilter & { district?: string | null } & LanguageFilter & DistrictFilter
		>({
			query: ({ page, take, orderField, order, source, district, languages, districts }) => ({
				url: `families`,
				params: { page, take, orderField, order, source, district, languages, districts },
			}),
			providesTags: ['Family'],
		}),
		getAllFamiliesByDistrictId: builder.query<FamilyDto[], { district?: string | null }>({
			query: ({ district }) => ({
				url: `families/by/district`,
				params: { district },
			}),
			providesTags: ['Family'],
		}),
		getAllFamiliesByList: builder.query<FamilyDto[], { families?: string[] }>({
			query: ({ families }) => ({
				url: `families/by/families-list`,
				params: { families },
			}),
			providesTags: ['Family'],
		}),
		getFamily: builder.query<FamilyDto, { id?: string | null }>({
			query: ({ id }) => ({
				url: `families/${id}`,
			}),
		}),
		createFamily: builder.mutation<FamilyDto, FormData>({
			query: (data) => ({
				url: `families`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: ['Family'],
		}),
		updateFamily: builder.mutation<FamilyDto, { id?: string; data: FormData }>({
			query: ({ id, data }) => ({
				url: `families/${id}`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['Family'],
		}),
		deleteFamily: builder.mutation<FamilyDto, { id: string }>({
			query: ({ id }) => ({
				url: `families/${id}`,
				method: 'DELETE',
				invalidatesTags: ['Family'],
			}),
		}),
		importFamilies: builder.mutation<{ success: []; failed: [] }, { formData: FormData; districtId?: string }>({
			query: ({ formData, districtId }) => ({
				url: `import/families${districtId ? `?districtId=${districtId}` : ''}`,
				method: 'POST',
				body: formData,
			}),
			invalidatesTags: ['Family'],
		}),
		getTemplateUrl: builder.query<{ url: string }, void>({
			query: () => ({
				url: 'import/families/template-url',
				method: 'GET',
			}),
		}),
	}),
});

export default familiesApi;

export const {
	useGetFamiliesListQuery,
	useGetAllFamiliesByDistrictIdQuery,
	useGetFamilyQuery,
	useGetAllFamiliesByListQuery,
	useCreateFamilyMutation,
	useUpdateFamilyMutation,
	useDeleteFamilyMutation,
	useImportFamiliesMutation,
	useGetTemplateUrlQuery,
} = familiesApi;
