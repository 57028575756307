import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from '../../../store';
import { EngagementDto } from '@families-link/shared';
import engagementsApi from '../../../store/api/engagements.api';

export type EngagementState = { currentEngagement: EngagementDto | null };

export const initialState = {
	currentEngagement: null,
};

export const engagementSlice = createSlice({
	name: 'engagement',
	initialState,
	reducers: {
		updateCurrentEngagement: (state: EngagementState, action) => {
			state.currentEngagement = action.payload;
		},
		clearCurrentEngagement: (state: EngagementState) => {
			state.currentEngagement = null;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(engagementsApi.endpoints.getEngagement.matchFulfilled, (state: EngagementState, action) => {
			state.currentEngagement = action.payload;
		});
	},
});

export const { updateCurrentEngagement, clearCurrentEngagement } = engagementSlice.actions;

export const useCurrentEngagementSelector = () => useAppSelector((state) => state.engagement.currentEngagement);
