import { Typography } from '@mui/material';
import { useGetDistrictWithoutInitiateQuery } from '../../../../store/api/district.api';

interface DistrictCellProps {
	districtId: string | null;
}

export const DistrictCell: React.FC<DistrictCellProps> = ({ districtId }) => {
	const { data: district } = useGetDistrictWithoutInitiateQuery({ id: districtId }, { skip: !districtId });

	if (district) {
		return <Typography>{district.name}</Typography>;
	}

	return <Typography>Unknown District</Typography>;
};
