import { IsOptional } from 'class-validator';

export class ProviderDto {
	_id: string;
	fullName: string;
	targetLanguage: string[];
	verificationType: boolean;
	workStatus: string;
	createdById?: string;
	districtId: string;
	serviceType: string;

	@IsOptional()
	totalEngagements?: number;

	@IsOptional()
	imageUrl?: string;
}
