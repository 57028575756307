import { IsEnum, IsNotEmpty } from 'class-validator';
import { SendMethodEnum, SendStatusEnum } from '../../enums';

export class CreateFamilySurveyTrackingDto {
	@IsNotEmpty()
	familyId: string;

	@IsNotEmpty()
	engagementSurveyId: string;

	@IsEnum(SendStatusEnum)
	sendStatus: string;

	@IsEnum(SendMethodEnum)
	sendMethod: string;
}
